<template>
  <v-layout class="greytext">
    <v-container class="mt-0" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="ggfont greytext">
        <v-col cols="12" md="9" xs="12">
          <h1>
            <span class="redtext"
              >{{ tarif.un_name }} {{ tarif.tarif_title }}</span
            >
            Heilpraktiker-Zusatzversicherung
          </h1>

          Die Heilpraktiker-Zusatzversicherung {{ tarif.un_name }}
          {{ tarif.tarif_title }} im Test. Der Naturheilkunde-Tarif
          {{ tarif.un_name }} {{ tarif.tarif_title }} wurde von DornsteinTabelle
          getestet. Dabei erreichte der Tarif eine durchschnittliche
          Leistungsstärke für naturheilkundliche Erst- und Folgebehandlungen
          innerhalb von 4 Jahren einen Wert von
          {{ tarif.hp_gesamterstattung_2_4 }} % Dornstein Realwert.
          <br />
          <v-btn
            style="color: white"
            color="#088A85"
            class="my-2 nocaps"
            @click="showAbschlussModal = !showAbschlussModal"
            >{{ tarif.un_name }} {{ tarif.tarif_title }} jetzt beantragen</v-btn
          >
          <div class="divider"></div>
        
          <h3 class="mt-4">
            Testbericht {{ tarif.un_name }}
            {{ tarif.tarif_title }} Zusatzversicherung Heilpraktiker |
            DornsteinTabelle
          </h3>

          <v-row class="my-4">
            <v-col cols="12" md="12">
              <v-btn
                class="button_caps"
                :class="{
                  'button-active': activeTab === 1,
                  'button-normal': activeTab !== 1,
                }"
                @click="activeTab = 1"
                >Leistungen
              </v-btn>
              <v-btn
                v-if="teste.length > 0"
                class="ml-4 button_caps"
                :class="{
                  'button-active': activeTab === 2,
                  'button-normal': activeTab !== 2,
                }"
                @click="activeTab = 2"
                >Testberichte
              </v-btn>
              <v-btn
                v-if="avb.length > 0"
                class="ml-4 button_caps"
                :class="{
                  'button-active': activeTab === 3,
                  'button-normal': activeTab !== 3,
                }"
                @click="activeTab = 3"
                >Downloads
              </v-btn>
              <v-btn
                v-if="preise.length > 0"
                class="ml-4 button_caps"
                :class="{
                  'button-active': activeTab === 4,
                  'button-normal': activeTab !== 4,
                }"
                @click="activeTab = 4"
                >Preise
              </v-btn>
              <v-btn
                v-if="kundenfragen.length > 0"
                class="ml-4 button_caps"
                :class="{
                  'button-active': activeTab === 5,
                  'button-normal': activeTab !== 5,
                }"
                @click="activeTab = 5"
                >Kundenfragen
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="activeTab == 1">
            <h3 class="my-2">
              Leistungsstärke nach Dornstein 4-Jahres Realwert-Methode
            </h3>
            <v-table>
              <tr>
                <td style="width: 340px">Ø-Erstattung in 4 Jahren:</td>
                <td>
                  <b class="redtext">{{ tarif.hp_gesamterstattung_2_4 }} %</b>
                </td>
              </tr>
              <tr>
                <td>Ø-Erstattung im 1 - 2 Jahr:</td>
                <td>
                  <b>{{ tarif.hp_wert_2 }} %</b>
                </td>
              </tr>
              <tr>
                <td>Ø-Erstattung im 3 -4 Jahr:</td>
                <td>
                  <b>{{ tarif.hp_wert_4 }} %</b>
                </td>
              </tr>
              <!-- <tr v-if="test_value">
                  <td>Stiftung Warentest Note:</td>
                  <td>
                    <b>{{ test_value }}</b>
                  </td>
                </tr> -->
              <!-- <tr v-if="preis_value">
                  <td>Beitrag Person 19 Jahre:</td>
                  <td>
                    <b>{{ preis_value }}</b>
                  </td>
                </tr> -->
            </v-table>

            <br />
            <v-btn
              style="color: white"
              color="#088A85"
              class="my-2 nocaps"
              @click="showAbschlussModal = !showAbschlussModal"
              >{{ tarif.un_name }} {{ tarif.tarif_title }} jetzt
              beantragen</v-btn
            >
            <div class="divider"></div>
            <h3 class="my-2">
              {{ tarif.un_name }}
              {{ tarif.tarif_title }} Leistungen für Naturheilkunde-Behandlungen
            </h3>
            Die Zusatzversicherung Naturheilverfahren {{ tarif.un_name }}
            {{ tarif.tarif_title }} leistet für folgende alternative
            Heilmethoden.<br /><br />
            <v-table>
              <tr style="vertical-align: top">
                <td style="width: 340px">
                  Maximale Erstattung für Naturheilkunde Behandlungen pro Jahr:
                </td>
                <td>
                  <b class="redtext">{{ tarif.hp_max_erstattung_1jahr }} €</b>
                </td>
              </tr>
              <tr>
                <td>Erstattung für Naturheilkunde Leistungen in Prozent:</td>
                <td>
                  <b>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  Maximale Gesamterstattung für Naturheilkunde Behandlungen:
                  <br /><span class="small"
                    >(Leistungen innerhalb der Mindestvertragslaufzeit)</span
                  >
                </td>
                <td>
                  <b>{{ tarif.hp_begrenzungen_hp }} </b>
                </td>
              </tr>
              <tr>
                <td>Leistung beim Heilpraktiker:</td>
                <td>
                  <b>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</b>
                </td>
              </tr>
              <tr>
                <td>Erstattung Hufelandverzeichnis:</td>
                <td>
                  <b>{{ tarif.hp_leistung_hufeland }}</b>
                </td>
              </tr>
              <tr>
                <td>
                  Erstattung beim Naturheilkunde-Arzt: <br /><span class="small"
                    >(Erstattung für Naturheilkunde Leistungen)</span
                  >
                </td>
                <td>
                  <b>{{ tarif.hp_naturheilkunde_arzt }} %</b>
                </td>
              </tr>
              <tr>
                <td>Erstattung GOÄ / GebüH:</td>
                <td>
                  <b>{{ tarif.hp_hoechstsatz }} </b>
                </td>
              </tr>
              <tr>
                <td>Leistung für Arzneimittel:</td>
                <td>
                  <b>{{ tarif.hp_leistung_arzneimittel }}</b>
                </td>
              </tr>

              <tr>
                <td>Der Tarif leistet für:</td>
                <td>
                  <b v-if="tarif.hp_heilmittel == 1">Heilmittel</b>

                  <b v-if="tarif.hp_medikamente == 1"> | Medikamente</b>
                </td>
              </tr>
            </v-table>

            <br />
            <v-btn
              style="color: white"
              color="#088A85"
              class="my-2 nocaps"
              @click="showAbschlussModal = !showAbschlussModal"
              >{{ tarif.un_name }} {{ tarif.tarif_title }} jetzt
              beantragen</v-btn
            >
            <div class="divider"></div>
            <h3 class="my-2">Weitere Gesundheitsleistungen des Tarifes</h3>
            <v-table>
              <tr>
                <td style="width: 340px">
                  Vorsorgeuntersuchungen / IGeL Leistungen:
                </td>
                <td>
                  <b>{{ tarif.hp_leistung_vorsorge }}</b>
                </td>
              </tr>
              <tr>
                <td>Schutzimpfungen:</td>
                <td>
                  <b>{{ tarif.hp_leistung_impfung }}</b>
                </td>
              </tr>
              <tr>
                <td>Sonstige Leistungen:</td>
                <td>
                  <b>{{ tarif.hp_leistung_weitere }}</b>
                </td>
              </tr>
            </v-table>

            <span v-if="tarif.hp_leistung_sehhilfe">
              <div class="divider"></div>
              <h3 class="my-2">Sehhilfe-Leistungen (Brille, Kontaktlinsen)</h3>
              <v-table>
                <tr>
                  <td style="width: 340px">Sehhilfen:</td>
                  <td>
                    <b>{{ tarif.hp_leistung_sehhilfe }}</b>
                  </td>
                </tr>
                <tr>
                  <td>LASIK:</td>
                  <td>
                    <b>{{ tarif.hp_leistung_lasik }}</b>
                  </td>
                </tr>
              </v-table>
            </span>
            <br />
            <v-btn
              style="color: white"
              color="#088A85"
              class="my-2 nocaps"
              @click="showAbschlussModal = !showAbschlussModal"
              >{{ tarif.un_name }} {{ tarif.tarif_title }} jetzt
              beantragen</v-btn
            >
            <div class="divider"></div>
            <h3 class="my-2">Tarifkonditionen</h3>
            <v-table>
              <tr>
                <td style="width: 340px">Wartezeit:</td>
                <td>
                  <b>{{ tarif.hp_wartezeit }}</b>
                </td>
              </tr>

              <tr>
                <td>Mindestvertragslaufzeit:</td>
                <td>
                  <b>{{ tarif.hp_mindestlaufzeit }}</b>
                </td>
              </tr>
              <tr style="vertical-align: top">
                <td>Altersrückstellungen:</td>
                <td>
                  <span v-if="tarif.tarif_altersrueckstellung == 0">
                    <b class="redtext">Ja</b>, der {{ tarif.un_name }}
                    {{ tarif.tarif_title }} ist mit Altersrückstellungen
                    kalkuliert. Stabile Beiträge im Alter.
                  </span>
                  <span v-if="tarif.tarif_altersrueckstellung == 1">
                    <b class="redtext">Nein</b>, der {{ tarif.un_name }}
                    {{ tarif.tarif_title }} ist ohne Altersrückstellungen
                    kalkuliert.
                  </span>
                </td>
              </tr>
            </v-table>

        

            <div class="divider"></div>
            <h3>
            {{ tarif.un_name }} {{ tarif.tarif_title }} Zusatzversicherung
            Heilpraktiker im Vergleich & Test
          </h3>

          <span v-html="tarif.hp_extra_highlights"></span>
          <br />
          <v-btn
            style="color: white"
            color="#088A85"
            class="my-2 nocaps"
            @click="showAbschlussModal = !showAbschlussModal"
            >{{ tarif.un_name }} {{ tarif.tarif_title }} jetzt beantragen</v-btn
          >
          <div class="divider"></div>
            <h3 class="my-2">
              Gesundheitsfragen der {{ tarif.un_name }}
              {{ tarif.tarif_title }} Heilpraktiker-Zusatzversicherung
            </h3>
            <span
              v-if="tarif.hp_gfragen_text != ''"
              v-html="tarif.hp_gfragen_text"
            ></span>
            <div
              style="background-color: #f2f2f2"
              v-for="frage in fragen"
              :key="frage.antrag_id"
              class="my-2 pa-4"
            >
              {{ frage.antrag_text }}
            </div>
          </div>
          <div v-show="activeTab == 2 && teste.length > 0">
            <table style="max-width: 400px">
              <tr v-for="(test, index) in teste" :key="`testberichte${index}`">
                <td style="width: 340px">
                  Test aus dem Jahr: {{ test.test_year }}
                </td>
                <td style="width: 120px">
                  <span class="blue--text">Note: {{ test.test_value }}</span>
                </td>
              </tr>
            </table>
          </div>

          <div v-show="activeTab == 3 && avb.length > 0">
            <h3>Downloads & Dokumente</h3>

            <ul>
              <li v-for="a in avb" :key="a.vdocs_id">
                <a :href="'/download/' + a.vdoc_dateiname" target="_blank">{{
                  a.vdoc_text
                }}</a>
              </li>
            </ul>
          </div>
          <div v-show="activeTab == 4 && preise.length > 0">
            <h3>Preise {{ tarif.un_name }} {{ tarif.tarif_title }}</h3>
            <v-table>
              <tr>
                <td style="width: 340px">Alter</td>
                <td>mtl. Beitrag</td>
              </tr>
              <tr v-for="preis in preise" :key="preis.preis_id">
                <td>{{ preis.preis_alter }}</td>
                <td>{{ preis.preis_value }} €</td>
              </tr>
            </v-table>
          </div>
          <div v-show="activeTab == 5 && kundenfragen.length > 0" class="my-4">
            <v-row v-for="frage in kundenfragen" :key="frage.faq_id" class="my-4 pa-4" style="background-color: #f2f2f2">
              <v-col cols="12">
                <b>Frage von {{ frage.faq_name_fragesteller }}</b>
                <h3>{{ frage.faq_title }}</h3>
                <em>{{ frage.faq_frage }}</em>
                <br> <br>
                <b>Antwort: DornsteinTabelle Experten-Team:
                </b><br>
                <span v-html="nl2br(frage.faq_antwort)"></span>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="3" xs="12" v-if="sharedDataStore.isDesktop()">
          <div style="background-color: #f2f2f2" class="pa-4">
            <span class="redtext" style="font-size: 48px"
              >{{ tarif.hp_gesamterstattung_2_4 }} %</span
            ><br />
            Dornstein Realwert
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog width="888" v-model="showAbschlussModal">
      <v-btn class="nocaps" @click="showAbschlussModal = !showAbschlussModal"
        >Fenster schließen</v-btn
      >
      <AbschlussModal :tarif="tarif" />
    </v-dialog>
  </v-layout>
</template>
<script setup>
  import { ref, watch, getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useRoute } from "vue-router";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useSharedDataStore } from "@/stores/sharedData";
  import AbschlussModal from "@/views/site/tarif/AbschlussModal.vue";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const loaderStore = useLoaderStore();
  const route = useRoute();
  const tarif = ref(false);
  const avb = ref([]);
  const activeTab = ref(1);
  const preise = ref([]);
  const teste = ref([]);
  const fragen = ref([]);
  const kundenfragen = ref([]);
  const showAbschlussModal = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  async function getTarif() {
    let select = {};
    select.action = "getTarifComplete";
    select.tarif_key = route.params.key;

    setLoading(true);
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarif) {
        console.log("getTarifSuccess");
        tarif.value = resp.data.tarif;
        teste.value = resp.data.test;
        avb.value = resp.data.avb;
        kundenfragen.value = resp.data.kundenfragen;
        preise.value = resp.data.preise;
        fragen.value = resp.data.fragen;
        saveSeo(1, resp.data.tarif.tarif_id);
        // weitere.value = resp.data.weitere;
        // waizmannwert.value = resp.data.waizmannwert;
        // moreThreads.value = resp.data.moreThreads;

        setLoading(false);

        proxy.$updateDocumentHead(
          tarif.value.un_name +
            " " +
            tarif.value.tarif_title +
            " Heilpraktiker-Zusatzversicherung | DornsteinTabelle",
          tarif.value.un_name +
            " " +
            tarif.value.tarif_title +
            " Zusatzversicherung Naturheilkunde. Tarif-Vergleich und Testbericht nach Dornstein 4-Jahres Realwert-Methode."
        );
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getTarif();
    },
    { deep: true }
  );
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    getTarif();
  });
</script>
<style scoped>
  .button-normal {
    color: black; /* Normale Schriftfarbe */
  }
  .button-active {
    color: #088a85; /* Aktive Schriftfarbe, ersetze #00bcd4 mit deiner Farbe */
  }
</style>
