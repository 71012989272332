<template>
  <v-layout class="greytext">
    <v-container class="mt-0">
      <v-row class="ggfont greytext">
        <v-col cols="12" md="12" xs="12">
          <v-row>
            <v-col cols="12" md="8" xs="12">
              <!-- neu -->
              <h1 style="padding-bottom: 0px; font-size: 32px">
                Heilpraktiker-Zusatzversicherung Vergleich
              </h1>
              <h2 style="color: #088a85">
                Die besten Heilpraktikerversicherungen {{common.getAktuellesJahr()}} auf einen Blick
              </h2>

              DornsteinTabelle wird von Heilpraktikerinnen und Heilpraktikern
              empfohlen. Osteopathen, Homöopathen, Chiropraktiker und
              Heilpraktiker für Psychotherapie nutzen DornsteinTabelle, um
              Patienten über Behandlungskosten und deren Absicherung
              aufzuklären. Auch in Heilpraktikerschulen findet DornsteinTabelle
              ihren Einsatz. Nutzen auch Sie jetzt DornsteinTabelle, die Nr. 1
              Vergleichstabelle für Heilpraktiker-Zusatzversicherungen, um eine
              leistungsstarke Heilpraktikerversicherung zu finden.
              <a href="/vergleich/starten/heilpraktiker"
                >Jetzt Online-Vergleich starten...</a
              >

              <br />
              <br />
            </v-col>
            <v-col cols="12" md="4" xs="12" class="pt-2">
              <div
                style="border-left: 4px solid; border-left-color: #088a85"
                class="pl-2"
              >
              <h2>Fit für die Reise – natürlich mit einer starken Naturheilkunde-Reiseapotheke!</h2>

24 erfahrene Heilpraktikerinnen und Heilpraktiker verraten auf DornsteinTabelle ihre bewährten <a href="/reiseapotheke">Tipps für die naturheilkundliche Reiseapotheke</a>. Für Groß und Klein – natürlich gut unterwegs!

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" xs="12">
              <v-card elevation="4" class="pb-2">
                <v-table dense>
                  <br />
                  <thead>
                    <tr class="redground">
                      <th colspan="5">
                        <h2 class="text-white">
                          Top 10 Heilpraktiker-Zusatzversicherungen
                        </h2>
                      </th>
                    </tr>
                    <tr style="background-color: #f2f2f2">
                      <th style="max-width: 10px">#</th>
                      <th>Tarif</th>

                      <th>
                        Dornstein RealWert<br />
                        in Prozent
                      </th>
                      <th>
                        Naturheilkunde Leistungen<br />
                        erste 2 Jahre
                      </th>
                      <th>Tarif-Highlights</th>
                      <th>Preis 18 Jahre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style="vertical-align: top"
                      v-for="tarif in tarife"
                      :key="tarif.id"
                    >
                      <td>{{ tarif.rank }}.</td>
                      <td>
                        <a
                          :href="`/heilpraktiker_zusatzversicherung/${tarif.tarif_key}`"
                        >
                          <strong>{{ tarif.versicherung }}</strong>
                        </a>

                        <br />
                        {{ tarif.tarif_title }}
                      </td>
                      <td>
                        <strong>{{ tarif.waizmannwert }}</strong>
                      </td>
                      <td>
                        <strong>{{ tarif.hp_erstattung_2jahre_euro }} €</strong>
                        <br />{{ tarif.hp_naturheilkunde_heilpraktiker }} %
                      </td>
                      <td>
                        <ul>
                          <span
                            v-html="showHighlight(tarif.hp_highlight_fields)"
                          ></span>
                        </ul>
                      </td>
                      <td>
                        <a
                          href="/vergleich/starten/heilpraktiker"
                          title="Vergleich Heilpraktikerversicherung starten"
                          >{{ tarif.preis_value }} €
                        </a>

                        <span class="green--text" v-if="tarif.hp_annahme == 1"
                          ><br /><b>Einfache</b> Annahme-bedingungen</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1"></td>
                      <td colspan="3">
                        <v-btn
                          depressed
                          tile
                          color="#088A85"
                          class="nocaps"
                          style="color: white"
                          href="/vergleich/starten/heilpraktiker"
                        >
                          Jetzt die besten Heilpraktikerversicherungen
                          vergleichen</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card>

              <v-row dense class="my-12">
                <v-row>
                  <!-- Generiert Spalten mit variabler Höhe -->
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="(item, index) in bilderUser"
                    :key="index"
                    class="mb-4"
                  >
                    <v-card class="d-flex flex-column">
                      <v-img
                        :src="getImagePath(item.image)"
                        class="text-white align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="280"
                      >
                        <!-- Verwende einen Container, um die Größe und den Überlauf des Titels zu steuern -->
                        <div
                          class="title-container d-flex align-end flex-grow-1"
                        >
                          <h3 class="text-white">{{ item.title }}</h3>
                        </div>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
              <v-row class="mt-12">
                <v-col cols="12" md="3">
                  <div
                    style="border-left: 4px solid; border-left-color: #088a85"
                  >
                    <v-avatar class="profile" tile color="grey" size="150">
                      <v-img
                        :src="require('@/assets/site/kilianlink.jpg')"
                      ></v-img>
                    </v-avatar>
                  </div>

                  <br /><b>Kilian Link</b>, Entwickler der
                  <b>Realwert-Methode</b> für Heilpraktiker-Zusatzversicherungen
                  <br /><br />
                  <h2>
                    <b style="font-size: 36px" class="redtext">Download</b
                    ><br />
                    DornsteinTabelle
                  </h2>
                  <a href="/dist/downloads/dornsteintabelle.pdf" target="_blank"
                    >Klicken Sie hier</a
                  >
                  um die aktuelle DornsteinTabelle für Ihre Praxisräume
                  <a href="/dist/downloads/dornsteintabelle.pdf" target="_blank"
                    >downzuloaden</a
                  >.
                  <br />
                  <a
                    href="/dist/downloads/dornsteintabelle.pdf"
                    target="_blank"
                  >
                    <v-avatar class="profile my-4" tile color="grey" size="150">
                      <v-img
                        :src="require('@/assets/site/tabellepicto.png')"
                      ></v-img> </v-avatar
                  ></a>
                  <br />

                  <br />
                </v-col>
                <v-col cols="12" md="9">
                  <h2>CHIROTHERAPIE - Wie mir kürzlich geholfen wurde</h2>

                  Vor nicht allzu langer Zeit plagten mich Schmerzen im rechten
                  Unterkiefer. Zunächst war es nicht so schlimm, dann steigerten
                  sich die Schmerzen. Zuletzt war es so schlimm, dass ich auf
                  der rechten Seite nicht mehr kauen konnte. Ich überlegte hin
                  und her, was wohl dahinter stecken könnte. So ging ich zu
                  einem Experten für Chirotherapie, da ich dort schon immer gute
                  Erfahrungen gemacht habe. Nach einer ausführlichen
                  Untersuchung wurden Probleme an der Wirbelsäule und am Atlas
                  diagnostiziert. Sanfte Griffe genügten, dann merkte ich den
                  Tag über schon eine Verbesserung. Einige Tage vergingen, dann
                  meldeten sich erneut leichte Schmerzen und Verspannungen. Nach
                  einer telefonischen Rücksprache erfolgte ein weiterer und
                  zugleich letzter Termin. Die Behandlung wurde ähnlich
                  durchgeführt und siehe da, nach der zweiten Behandlung ging es
                  mir kurz danach wieder richtig gut. Ich musste mich regelrecht
                  kneifen, wie so eine kleine Sache, große Kreise zog und solch
                  Schmerzen verursachen konnte.

                  <br />
                  <br />
                  <h2>DornsteinTabelle Themenübersicht</h2>
                  <ul>
                    <li>
                      <a href="/vergleich/starten/heilpraktiker"
                        >Heilpraktikerversicherung Vergleich</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherungen_in_Kuerze'
                          )
                        "
                        >Das Wichtigste zu Heilpraktikerversicherungen in
                        Kürze</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Welche_Heilpraktiker_Zusatzversicherungen'
                          )
                        "
                      >
                        Welche Heilpraktiker-Tarife Sie auf DornsteinTabelle
                        finden</a
                      >
                    </li>
                    <li>
                      <a @click="scrollMeTo('kunden_ueber_dornsteintabelle')"
                        >Kunden über die DornsteinTabelle</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Woran_erkenne_ich_eine_gute_Heilpraktiker_Zusatzversicherung'
                          )
                        "
                      >
                        Welche Leistungen gute Heilpraktikerversicherungen
                        bieten</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Ratgeber_Heilpraktiker_Zusatzversicherungen'
                          )
                        "
                        >Die 6 wichtigsten Ratgeber</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo('FAQ_Heilpraktiker_Zusatzversicherungen')
                        "
                        >FAQ - 5 häufig gestellte Fragen</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Kinder_Heilpraktiker_Zusatzversicherungen'
                          )
                        "
                        >Heilpraktikerversicherung für Kinder</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherungen_ohne_Gesundheitsfragen'
                          )
                        "
                      >
                        Heilpraktiker-Zusatzversicherungen ohne
                        Gesundheitsfragen</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherungen_mit_Vorerkrankung'
                          )
                        "
                        >Heilpraktiker-Zusatzversicherung bei Vorerkrankung</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherungen_mit_Psychotherapie'
                          )
                        "
                        >Heilpraktiker-Zusatzversicherungen für
                        Psychotherapie</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherung_sinnvoll'
                          )
                        "
                        >Heilpraktiker-Zusatzversicherung sinnvoll - Ja /
                        Nein</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo('Heilpraktiker_Zusatzversicherung_Brille')
                        "
                      >
                        Heilpraktiker-Zusatzversicherungen mit Leistungen für
                        Brille</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo('Heilpraktiker_Zusatzversicherung_Test')
                        "
                        >Heilpraktiker-Zusatzversicherung Test - So haben wir
                        getestet</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherung_Begrenzung'
                          )
                        "
                      >
                        Heilpraktiker-Zusatzversicherung 100 % - Leistung ohne
                        Begrenzungen</a
                      >
                    </li>
                    <li>
                      <a
                        @click="
                          scrollMeTo(
                            'Heilpraktiker_Zusatzversicherung_Wartezeit'
                          )
                        "
                        >Heilpraktiker-Zusatzversicherungen ohne Wartezeit</a
                      >
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="12" md="3">
           
            </v-col> -->
          </v-row>

          <v-row class="ggfont greytext">
            <v-col cols="12" md="9">
              <br />
              <div id="Heilpraktiker_Zusatzversicherungen_in_Kuerze"></div>
              <h2 class="mt-4 mb-2">
                Heilpraktiker-Zusatzversicherungen - Die wichtigsten Infos in
                Kürze
              </h2>
              <ul class="my-4">
                <li>
                  Wer gesetzlich krankenversichert ist, muss die Kosten für
                  Behandlungen beim Heilpraktiker selbst bezahlen.
                </li>
                <li>
                  Mit einer privaten Heilpraktiker-Zusatzversicherung können
                  sich Patienten hohe Kostenerstattungen beim Heilpraktiker
                  sichern.
                </li>
                <li>
                  Die besten Heilpraktiker-Zusatzversicherungen erstatten bis
                  zum Höchstbetrag Hufelandverzeichnis / GebüH / GOÄ.
                </li>
                <li>
                  Leistungsstarke Heilpraktiker-Tarife leisten 100 % bis zu
                  2.000,- € für Naturheilkunde-Behandlungen pro Jahr.
                </li>
                <li>
                  Kindertarife erfreuen sich wachsender Beliebtheit. Immer mehr
                  Eltern setzen auf naturheilkundliche Behandlungen für ihre
                  Kinder.
                </li>
              </ul>
              <div id="Welche_Heilpraktiker_Zusatzversicherungen"></div>
              <h2 class="mt-4 mb-2">
                Welche Heilpraktiker-Zusatzversicherungen listet
                DornsteinTabelle?
              </h2>
              Wir analysieren den Markt an Heilpraktiker Zusatzversicherungen,
              testen Heilpraktikerversicherungen und listen die
              leistungsstärksten Tarife auf DornsteinTabelle. Sie finden bei uns
              die besten Zusatzversicherungen für Naturheilkunde / alternative
              Heilmethoden auf einen Blick. Unsere Tarife erstatten Behandlungen
              beim Heilpraktiker und bei Ärzten für Naturheilverfahren und
              leisten bis zum Höchstbetrag nach Gebührenordnung.
              DornsteinTabelle listet:
              <ol class="mt-2">
                <li>
                  Exzellente Heilpraktikerversicherungen von Marktführern &
                  Testsieger-Tarife
                </li>
                <li>
                  Die besten Kinder Heilpraktikerversicherungen getestet von den
                  Experten der DornsteinTabelle
                </li>

                <li>
                  Tarife mit Erstattung bis zum Höchstbetrag nach
                  Gebührenordnung für Heilpraktiker (GebüH) und
                  Hufelandverzeichnis / GÖÄ
                </li>
                <li>
                  Leistungsstarke Heilpraktiker-Zusatztarife mit 100% Erstattung
                  für sämtliche Naturheilverfahren bis 2.000 € pro Jahr
                </li>
                <li>
                  Beste Heilpraktiker-Zusatzversicherungen mit einfachen
                  Gesundheitsfragen
                </li>
                <li>
                  Die top 3 Heilpraktiker-Zusatzversicherungen für
                  Psychotherapie
                </li>
                <li>
                  Heilpraktikerversicherungen, die man trotz Vorerkrankung
                  abschließen kann
                </li>
                <li>
                  Heilpraktiker-Zusatzversicherungen mit Leistungen für
                  Sehhilfen z.B. Brille
                </li>
                <li>Heilpraktiker-Zusatzversicherungen ohne Wartezeit</li>
              </ol>
              <div
                id="Woran_erkenne_ich_eine_gute_Heilpraktiker_Zusatzversicherung"
              ></div>
              <h2 class="mt-4 mb-2">
                Woran erkenne ich eine gute Heilpraktiker-Zusatzversicherung?
              </h2>
              <b
                >Eine gute Heilpraktiker-Zusatzversicherung bietet folgende
                Leistungen:</b
              >
              <ul class="my-2">
                <li>
                  <b>Leistungen für klassische Naturheilverfahren </b><br />
                  Diese umfassen z.B. Kräuterheilkunde, Ordnungstherapie, Hydro-
                  und Thermotherapie, Ernährungstherapie und Bewegungstherapie.
                </li>
                <li>
                  <b>Leistungen für traditionelle Naturheilverfahren </b><br />
                  Diese umfassen z. B. Traditionelle Chinesische Medizin (TCM),
                  Ayurveda, ausleitende Verfahren.
                </li>
                <li>
                  <b
                    >Leistungen für alternative Heilmethoden /
                    Alternativmedizin</b
                  ><br />
                  Bei Behandlungen nach Verfahren der Naturheilkunde wird der
                  Patient ganzheitlich wahrgenommen. Akupunktur, Homöopathie,
                  Chirotherapie, Osteopathie, Hydro- und Phytotherapie gehören
                  dabei zu den bekanntesten Methoden der Alternativmedizin. Eine
                  Naturheilkunde-Zusatzversicherung soll für Behandlungen bei
                  Heilpraktikern und für Behandlungen bei Ärzten für
                  Naturheilverfahren die Behandlungskosten übernehmen.
                </li>
                <li>
                  <b>Leistungen für Heilmittel / Hilfsmittel</b><br />
                  Die Erstattung für Heilmittel (z.B. Massagen) und Hilfsmittel
                  (z.B. Bandagen) soll nach GebüH und für die im
                  Hufelandverzeichnis aufgeführten Therapien / Anwendungen
                  gegeben sein.
                </li>
                <li>
                  <b>Leistungen für Medikamente und Verbandmittel</b><br />
                  Die Erstattung der mit der Behandlung in Verbindung stehenden
                  Medikamente / Verbandmittel soll erbracht werden.
                </li>
                <li>
                  <b>Zusatzleistungen in ambulanten Versicherungen</b><br />
                  Zusatzleistungen wie Vorsorgeuntersuchungen und
                  Schutzimpfungen (ggf. Sehhilfe-Leistungen) sind meist
                  inkludiert und runden den ambulanten Zusatztarif für
                  Naturheilkunde ab.
                </li>
              </ul>
              <h2 class="mt-12 mb-2">
                Was es zudem zu wissen gibt: Versicherungsbudget, prozentuale
                Erstattung, Gebührenordnung und Beiträge
              </h2>
              <ul class="my-2">
                <li>
                  <b
                    >Dieses jährliche Leistungs-Budget soll eine gute
                    Heilpraktikerversicherung bieten:</b
                  ><br />
                  Für Erwachsene soll ein Budget für Naturheilkunde-Behandlungen
                  in Höhe von mindestens 800,- € - 1.000,- € abgesichert sein.
                  Bei Kindern soll ein Budget für Naturheilkunde-Behandlungen in
                  Höhe von 800,- € abgesichert sein.
                </li>

                <li>
                  <b
                    >Diese prozentuale Kostenerstattung soll eine
                    Zusatzversicherung für Heilpraktiker abdecken:</b
                  ><br />
                  100 % Kostenerstattung auf das Versicherungsbudget sind ideal,
                  80 % in vielen Tarifen die gängige Höhe der Kostenerstattung.
                </li>

                <li>
                  <b
                    >Das gibt es zu Erstattungshöhen der Gebührenordnungen zu
                    beachten:</b
                  ><br />
                  Leistungen sollen bis zum Höchstbetrag nach Gebührenordnung
                  für Heilpraktiker (GebüH) und Hufelandverzeichnis / GÖÄ
                  erstattet werden.
                </li>

                <li>
                  <b
                    >Das kostet eine gute Heilpraktiker-Zusatzversicherung
                    monatlich an Beitrag:</b
                  ><br />
                  Kindertarife kosten zwischen 8,- € - 12,- € ,
                  Erwachsenen-Tarife (40 Jahre) kosten zwischen 20,- € - 30,- €.
                </li>
              </ul>

              <div id="Ratgeber_Heilpraktiker_Zusatzversicherungen"></div>
              <h2 class="mt-12 mb-2">
                Die 6 wichtigsten Ratgeber auf einen Blick
              </h2>
              <v-row style="background-color: #f2f2f2">
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">Kinder Heilpraktikerversicherungen</h4>

                  <a href="/heilpraktikerversicherung_kind_vergleich"
                    >Lesen Sie jetzt, warum sich eine Heilpraktikerversicherung
                    für Kinder auszahlt</a
                  >
                </v-col>
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">
                    Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen
                  </h4>

                  Jede Heilpraktiker-Zusatzversicherung stellt
                  Gesundheitsfragen.
                  <a
                    href="/heilpraktiker_zusatzversicherung_ohne_gesundheitsfragen"
                    >Es gibt aber auch Tarife mit einfachen Gesundheitsfragen</a
                  >
                </v-col>
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">
                    Heilpraktiker-Zusatzversicherung sinnvoll
                  </h4>

                  <a href="/heilpraktiker_zusatzversicherung_sinnvoll"
                    >Lesen Sie jetzt, wann sich eine Zusatzversicherung beim
                    Heilpraktiker lohnt und wann nicht</a
                  >
                </v-col>
              </v-row>
              <v-row class="mt-4" style="background-color: #f2f2f2">
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">
                    Heilpraktiker-Zusatzversicherung bei Vorerkrankung
                  </h4>

                  <a href="/heilpraktiker_zusatzversicherung_bei_vorerkrankung"
                    >Eine Heilpraktiker Zusatzversicherung kann auch trotz
                    Vorerkrankungen abgeschlossen werden. Jetzt mehr erfahren</a
                  >
                </v-col>
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">
                    Heilpraktiker-Zusatzversicherung für Psychotherapie
                  </h4>

                  Nur wenige Heilpraktiker Zusatzversicherungen bieten eine
                  Leistungen für Psychotherapie,
                  <a
                    href="/heilpraktiker_zusatzversicherung_fuer_psychotherapie"
                    >jetzt mehr erfahren</a
                  >
                </v-col>
                <v-col cols="12" md="4" class="pr-2"
                  ><h4 class="mb-2">Heilpraktiker-Zusatzversicherung Test</h4>

                  <a href="/heilpraktiker_zusatzversicherungen_im_test"
                    >Stiftung Warentest testet Heilpraktiker
                    Zusatzversicherungen eher selten. DornsteinTabelle testet
                    kontinuierlich. Jetzt mehr erfahren
                  </a>
                </v-col>
              </v-row>
              <div id="FAQ_Heilpraktiker_Zusatzversicherungen"></div>
              <h2 class="mt-12 mb-2">
                FAQ - 5 Häufig gestellte Fragen zur
                Heilpraktiker-Zusatzversicherung
              </h2>
              <h4>Was kostet eine gute Heilpraktiker-Zusatzversicherung?</h4>
              Leistungsstarke Heilpraktikerversicherungen kosten zwischen 20,-
              bis 30,- € pro Monat für eine vierzigjährige Person. Für
              Naturheilkunde-Behandlungen / alternative Heilmethoden sollte
              mindestens ein Budget zwischen 800,- bis 1.000,- € pro Jahr
              vorhanden sein. Die aktuell höchsten Budgets einer privaten
              Heilpraktiker Zusatzversicherung liegen bei 2.000,- € pro Jahr.
              <br /><br />
              <h4>
                Wie viel kostet eine gute Kinder-Heilpraktikerversicherung?
              </h4>
              Heilpraktikerversicherungen für Kinder kosten zwischen 8,- bis
              12,- € pro Monat. Naturheilkunde-Behandlungen sollten mit 800,- €
              pro Jahr erstattet werden. Der aktuell beliebteste Kindertarif
              heißt
              <a
                href="/heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_1000"
                >Barmenia Mehr Gesundheit 1.000</a
              >. Dieser bietet ein Budget für Naturheilkunde-Behandlungen von
              1.000,- € pro Jahr. <br /><br />
              <h4>
                Welche Leistungen bietet eine Heilpraktiker-Zusatzversicherung?
              </h4>
              Typische Naturheilkunde-Leistungen umfassen Bereiche wie z.B.
              Akupunktur, Chiropraktik, Homöopathie (z.B. Globuli), Massagen,
              Osteopathie sowie traditionell chinesische Medizin (TCM). Zudem
              leisten die meisten ambulanten Naturheilkunde-Tarife auch für
              Vorsorgeuntersuchungen, Impfungen und Sehhilfen (z.B. Brille)
              sowie für Arznei- und Heil- und Hilfsmittel.
              <br /><br />
              <h4>
                Bei welchen Behandlern leistet eine
                Heilpraktiker-Zusatzversicherung?
              </h4>
              Eine gute Heilpraktiker Zusatzversicherung soll bei Heilpraktikern
              und bei Ärzten für Naturheilverfahren leisten. So haben Patienten
              die Wahl, wohin sie für eine Behandlung gehen möchten. Die
              Tarifleistungen sollen alle Positionen des Gebührenverzeichnisses
              für Heilpraktiker (GebüH) umfassen und für sämtliche
              Naturheilverfahren des Hufelandverzeichnisses leisten. Die
              Erstattung soll bis zum Höchstbetrag Hufelandverzeichnis sowie
              Erstattung bis zum Höchstbetrag GOÄ / GebüH gegeben sein.
              <br /><br />
              <h4>
                Lohnt sich eine Heilpraktiker-Zusatzversicherung überhaupt?
              </h4>
              Eine Heilpraktiker-Zusatzversicherung lohnt sich für gesetzlich
              Versicherte, die häufiger naturheilkundliche Behandlungen bei
              einem Heilpraktiker in Anspruch nehmen möchten. Naturheilkundliche
              Behandlungen sollten aber nicht nur kurzfristig genutzt werden, da
              eine Zusatzversicherung für alternative Heilmethoden für ein oder
              zwei Jahre abgeschlossen werden muss.

              <div id="Kinder_Heilpraktiker_Zusatzversicherungen"></div>
              <h2 class="mt-12 mb-2">
                Kinder Heilpraktikerversicherungen Vergleich & Testsieger
              </h2>
              Ist eine Heilpraktiker-Zusatzversicherung für Kinder sinnvoll? Ja!
              Besonders die Kleinsten profitieren von einem ganzheitlichen
              Behandlungsansatz. Schonende Heilung auf natürliche Weise und das
              zu günstigen Versicherungsbeiträgen.
              <br /><br />

              DornsteinTabelle hat Kinder Heilpraktikerversicherungen getestet
              und listet diese in der DornsteinTabelle Kinder. Wir bieten Eltern
              6 getestete Naturheilkunde-Zusatzversicherungen für Kinder, welche
              uneingeschränkt wie Erwachsenen-Tarife leisten. Das bedeutet, dass
              diese Tarife auch später im Erwachsenenalter weitergeführt werden
              können. Zudem bieten diese 6 Tarife extra Vorsorgeleistungen für
              Kinder, die im Versicherungsschutz enthalten sind.

              <h4 class="mt-4">
                Die 3 Vorteile einer Kinder Heilpraktikerversicherung
              </h4>
              <ol class="my-2">
                <li>
                  <b>Gesundheitsfragen bei Abschluss:</b> Die Annahme bei einer
                  Heilpraktiker-Zusatzversicherung ist mit Gesundheitsfragen
                  verbunden. Da Kinder i.d.R. noch keine schweren Krankheiten
                  oder chronische Krankheiten haben, ist hier die Annahme
                  besonders einfach.
                </li>
                <li>
                  <b>Günstige Beiträge:</b> Eine
                  Kinder-Heilpraktikerversicherung kostet ca. 8 - 12 €
                  monatlich. Für Kinder gilt: Viel Naturheilkunde-Leistung zum
                  kleinen Preis.
                </li>
                <li>
                  <b>Heilmethoden für Kinder:</b> Besonders Kinder profitieren
                  von einer ganzheitlichen Behandlung. DornsteinTabelle bietet
                  Naturheilkunde Zusatzversicherungen, welche sowohl beim
                  Heilpraktiker als auch beim Arzt für Naturheilverfahren in
                  Anspruch genommen werden können.
                </li>
              </ol>
              Hier finden Sie ausführliche Informationen der DornsteinTabelle
              Experten zu:
              <a href="/heilpraktikerversicherung_kind_vergleich"
                >Heilpraktikerversicherungen für Kinder</a
              >
              <div
                id="Heilpraktiker_Zusatzversicherungen_ohne_Gesundheitsfragen"
              ></div>
              <h2 class="mt-12 mb-2">
                Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen
              </h2>
              Heilpraktiker-Zusatzversicherungen ohne Gesundheitsfragen gibt es
              leider nicht. Der Abschluss einer Heilpraktikerversicherung ist
              immer mit der Beantwortung von Gesundheitsfragen verbunden. Wenn
              man so will, muss man im Sinne der Versicherung “gesund genug”
              sein, um angenommen zu werden.

              <br /><br />
              <h4>Was ist unter Gesundheitsfragen zu verstehen?</h4>
              Im Rahmen der Gesundheitsprüfung (im Antrag werden
              Gesundheitsfragen gestellt) werden neben schweren Erkrankungen /
              Vorerkrankungen und chronischen Krankheiten auch länger
              zurückliegende Zeiträume abgefragt. Eine Gesundheitsprüfung kann
              bei Heilpraktikerversicherungen 10 Jahre rückwirkend bezogen auf
              psychotherapeutische Behandlungen, 5 Jahre rückwirkend bezogen auf
              stationäre Krankenhausaufenthalte sowie 3 Jahre rückwirkend
              bezogen auf Krankheiten umfassen. Zudem kann auch nach
              Einschränkungen, Behinderungen, Operationen sowie Medikamenten
              gefragt werden.

              <b>TIPP:</b> Einige Heilpraktiker Zusatzversicherungen stellen
              einfache Gesundheitsfragen. Die Annahme ist also leichter, da die
              Gesundheitsprüfung nicht so ausführlich / nicht so weitreichend
              ist. Weitere Informationen der DornsteinTabelle Experten finden
              Sie unter:
              <a href="/heilpraktiker_zusatzversicherung_ohne_gesundheitsfragen"
                >Heilpraktiker-Zusatzversicherungen ohne Gesundheitsfragen</a
              >

              <h4>
                Heilpraktiker-Zusatzversicherungen mit einer Gesundheitsfrage /
                einfachen Gesundheitsfragen
              </h4>
              Stellt die Heilpraktiker-Zusatzversicherung nur eine
              Gesundheitsfrage, werden konkret sämtliche Diagnosen /
              Krankheitsbilder / Erkrankungen / Einschränkungen abgefragt.
              Hierbei wird also gezielt nach Krankheitsbildern / Diagnosen
              gefragt. Ein großer Vorteil. Wenn die aufgelisteten Diagnosen
              nicht auf Sie zutreffen, steht einer Annahme in der Versicherung
              nichts im Wege.

              <br /><br />
              Stellt die Heilpraktiker-Zusatzversicherung vereinfachte
              Gesundheitsfragen, geht es dabei um Fragen zu Erkrankungen,
              schweren Erkrankungen sowie chronischen Erkrankungen der letzten 3
              bis 5 Jahre. Auch Fragen zu Körpergewicht und Größe (BMI) sowie
              Volkskrankheiten wie Rückenschmerzen können gestellt werden. Auch
              hier gilt, wenn die aufgelisteten Diagnosen nicht auf Sie
              zutreffen, steht einer Annahme in der Versicherung nichts im Wege.

              <div
                id="Heilpraktiker_Zusatzversicherungen_mit_Vorerkrankung"
              ></div>
              <h2 class="mt-12 mb-2">
                Heilpraktiker-Zusatzversicherung mit Vorerkrankung
              </h2>
              Eine Heilpraktiker-Zusatzversicherung kann auch trotz
              Vorerkrankung abgeschlossen werden. Jedoch kommt es auf die
              Vorerkrankung, sprich die gestellte Diagnose, die Erkrankung an.
              Demnach ist es nicht ganz einfach, einen passenden Tarif zu
              finden, der eine Annahme trotz Vorerkrankung ermöglicht.
              <br /><br />

              <b>TIPP:</b> Wir helfen, auch bei Vorerkrankungen einen passenden
              Heilpraktiker-Tarif zu finden. Wir bieten drei
              Heilpraktiker-Tarife mit sog. abschließend gestellten
              Gesundheitsfragen. Weitere Informationen der DornsteinTabelle
              Experten finden Sie unter:
              <a href="/heilpraktiker_zusatzversicherung_bei_vorerkrankung"
                >Heilpraktiker-Zusatzversicherung bei Vorerkrankung</a
              >
              <div
                id="Heilpraktiker_Zusatzversicherungen_mit_Psychotherapie"
              ></div>
              <h2 class="mt-12 mb-2">
                Heilpraktiker-Zusatzversicherung Psychotherapie
              </h2>
              Aktuell bieten nur 3 Heilpraktiker-Tarife gute Leistungen für
              Psychotherapie. Hierbei können Leistungen bis zu 2.000 € pro
              Versicherungsjahr in Anspruch genommen werden. Dieser
              Leistungspunkt Psychotherapie ist auch besonders, da man keinen
              Heilpraktiker-Tarif für eine psychotherapeutische Behandlung
              abschließen kann, wenn schon feststeht, dass eine solche benötigt
              wird.

              <br /><br />
              <h4>Wenn es noch keine “Diagnose Psychotherapie” gibt</h4>
              Bei der Wahl einer Heilpraktiker Zusatzversicherung kann man einen
              Heilpraktiker-Tarif wählen, der für Psychotherapie leistet. Dann
              ist eine psychotherapeutische Behandlung bei z.B. Burnout /
              depressiver Verstimmung durch einen ausgebildeten Heilpraktiker
              für Psychotherapie versichert. Eine echte Option, da die
              GKV-Leistung für Psychotherapie oft mit langen Wartezeiten
              aufgrund hoher Nachfrage verbunden ist. Finden Sie ausführlichere
              Informationen der DornsteinTabelle Experten zu:
              <a href="/heilpraktiker_zusatzversicherung_fuer_psychotherapie"
                >Heilpraktiker-Zusatzversicherung für Psychotherapie</a
              >
              <div id="Heilpraktiker_Zusatzversicherung_sinnvoll"></div>
              <h2 class="mt-12 mb-2">
                Ist eine Heilpraktiker-Zusatzversicherung sinnvoll?
              </h2>
              Ja, für jeden gesetzlich Versicherten, der häufiger zu
              Heilpraktikern gehen möchte. Naturheilkundliche Behandlungen /
              alternative Heilverfahren können mit einer Heilpraktiker
              Zusatzversicherung umfangreich genutzt werden. Für viele Menschen
              ist längst klar, lieber den Heilpraktiker, Chiropraktiker,
              Osteopathen etc. konsultieren, z.B. Homöopathie oder TCM zu
              nutzen, als nur der klassischen Schulmedizin zu folgen. Die
              Alternativmedizin ist mit ihren umfassenden
              Behandlungsmöglichkeiten gefragter denn je. Weitere Informationen
              path: "/heilpraktiker_zusatzversicherung_sinnvoll", der
              DornsteinTabelle Experten finden Sie unter:
              <a href="/heilpraktiker_zusatzversicherung_sinnvoll"
                >Ist eine Heilpraktiker-Zusatzversicherung sinnvoll?</a
              >
              <div id="Heilpraktiker_Zusatzversicherung_Brille"></div>
              <h2 class="mt-12 mb-2">
                Heilpraktiker-Zusatzversicherung + Brille
              </h2>
              Heilpraktikerversicherungen haben meistens Sehhilfe-Leistungen
              (z.B. Brille, Kontaktlinsen) im Tarif bereits inkludiert. Ganz
              allgemein werden ambulante Zusatzversicherungen mit Fokus
              Naturheilkunde in Kombination mit weiteren Gesundheitsleistungen
              wie z.B. Sehhilfe-Leistungen, Vorsorgeleistungen, Schutzimpfungen,
              Leistungen für Arzneimittel und Heilmittel angeboten. Bei einigen
              Heilpraktikerversicherungen können Leistungen für Sehhilfen
              (Brille, Kontaktlinsen, Sonnenbrille, LASIK) in den Tarif
              zusätzlich inkludiert werden. In den meisten
              Heilpraktikerversicherungen sind Leistungen für Sehhilfen bereits
              im Tarif enthalten. Heilpraktiker- und Brillenzusatzversicherungen
              bieten Sehhilfe Leistungen, welche meist alle zwei Jahre mit einem
              Budget von 240,- € bis 400,- € in Anspruch genommen werden können.
              <router-link to="/heilpraktiker_und_brillenzusatzversicherungen"
                >DornsteinTabelle Heilpraktiker- und Brillenzusatzversicherung
                Tarif-Empfehlungen im Überblick</router-link
              >
              <div id="Heilpraktiker_Zusatzversicherung_Test"></div>
              <h2 class="mt-12 mb-2">Heilpraktiker-Zusatzversicherung Test</h2>
              Stiftung Warentest / Finanztest hat
              Heilpraktiker-Zusatzversicherungen in der Ausgabe 5/2017 getestet.
              Dabei wurden 59 Heilpraktiker-Zusatzversicherungen unter die Lupe
              genommen, wovon die Hälfte mit “gut” bewertet wurde.
              <br /><br />
              <h4>
                DornsteinTabelle testet Heilpraktiker-Zusatzversicherungen mit
                der Dornstein Realwert-Methode
              </h4>
              DornsteinTabelle bietet eine versicherungsunabhängige Tarifanalyse
              von Heilpraktiker-Zusatzversicherungen. Dabei zeigt
              DornsteinTabelle die durchschnittliche Leistungsstärke
              unterschiedlicher Naturheilkunde-Tarife und macht diese
              miteinander vergleichbar. Zur Tarifanalyse wurde die
              4-Jahres-Realwert-Methode, als neuer Bewertungsstandard für
              Heilpraktiker-Zusatzversicherungen, entwickelt, welche auf einem
              “Warenkorb von Naturheilkunde Behandlungen” basiert , die ein
              Musterpatient im Laufe von 4 Jahren in Anspruch nimmt.
              Darüberhinaus enthält die 4-Jahres-Realwert-Methode weitere
              Qualitätskriterien, um leistungsstarke Heilpraktikerversicherungen
              zu identifizieren. Weitere Infos zur
              <a href="/realwert_heilpraktikerversicherung"
                >4-Jahres-Realwert-Methode</a
              >
              <br /><br />
              <b>Gut zu wissen</b>. DornsteinTabelle vergleicht und analysiert
              Heilpraktiker-Zusatzversicherungen kontinuierlich. Die Testsieger
              werden in einer
              <a href="/heilpraktiker_zusatzversicherungen_im_test"
                >Vergleichstabelle inkl. Testberichte</a
              >
              tagesaktuell online gelistet. Zudem testet DornsteinTabelle auch
              explizit Kinder Heilpraktiker-Zusatzversicherungen, welche bei
              Stiftung Warentest / Finanztest nicht extra analysiert werden.
              <div id="Heilpraktiker_Zusatzversicherung_Begrenzung"></div>
              <h2 class="mt-4 mb-2">
                Heilpraktiker-Zusatzversicherung 100% Leistung - ohne Begrenzung
              </h2>
              Heilpraktikerversicherungen können bis zu 100 % leisten und bis
              zum Höchstsatz des Gebührenverzeichnisses / der Gebührenordnung
              erstatten. Es gibt also Heilpraktiker Zusatzversicherungen, die
              für 100 % auf 1.000,- € oder 100 % auf 2.000 € leisten. Das Budget
              der Zusatzversicherung ist jedoch immer auf eine jährliche
              Höchstgrenze von z.B. 1.000,- € bzw. 2.000,- € pro
              Versicherungsjahr begrenzt.
              <a href="heilpraktiker_zusatzversicherung_ohne_begrenzungen"
                >Mehr Infos zu Heilpraktiker-Zusatzversicherung ohne
                Begrenzung...</a
              >
              <div id="Heilpraktiker_Zusatzversicherung_Wartezeit"></div>
              <h2 class="mt-12 mb-2">
                Heilpraktiker-Zusatzversicherung ohne Wartezeit
              </h2>
              Einige Heilpraktikerversicherungen können ohne Wartezeit
              abgeschlossen werden. Die versicherte Person kann dann sofort
              Leistungen in Anspruch nehmen. Für angeratene, geplante, bereits
              laufende Behandlungen wird jedoch nicht erstattet. Die allgemeine
              Wartezeit für eine Heilpraktikerversicherung beträgt 3 Monate. Die
              besondere Wartezeit beträgt 8 Monate und gilt für Entbindung und
              Psychotherapie. Ausführliche zum Thema "<a
                href="heilpraktiker_zusatzversicherung_ohne_wartezeit"
                >Heilpraktiker-Zusatzversicherung ohne Wartezeit</a
              >"...
              <v-divider :thickness="4" class="my-4" color="turk"></v-divider>

              <h2>Heilpraktiker-Zusatzversicherung Familienversicherung</h2>

              Gibt es eine Heilpraktikerversicherung als Familienversicherung
              oder Partnertarif? Diese Frage wird uns immer wieder gestellt. Die
              Antwort lautet: Nein. Entgegen der gesetzlichen
              Krankenversicherung gibt es weder eine Familienversicherung noch
              einen Partnertarif. Für jede zu versichernde Person muss ein
              einzelner Vertrag abgeschlossen werden. Erfahren Sie mehr, warum
              es keine
              <router-link
                to="/heilpraktiker_zusatzversicherung_familie_partner"
                >Heilpraktikerversicherung als Familienversicherung</router-link
              >
              gibt.

              <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
              <h2>Heilpraktiker-Zusatzversicherung kündigen</h2>
              Selbstverständlich gibt es immer Gründe eine Zusatzversicherung zu
              kündigen. Bei der Heilpraktiker-Zusatzversicherung handelt es sich
              jedoch vielleicht um einen Sonderfall. Hier lohnt es sich, vor der
              Kündigung nochmal kurz inne zu halten. Kündige ich meine
              Heilpraktikerversicherung vorschnell und möchte später wieder
              einen Heilpraktiker-Tarif abschließen, ist dies ein Neuabschluss.
              Und bei jedem Neuabschluss einer Heilpraktiker-Zusatzversicherung
              sind immer teils umfangreiche Gesundheitsfragen zu beantworten,
              welche meist zwischen drei bis fünf Jahre rückwirkend bis hin zu
              zehn Jahren rückwirkend zu beantworten sind. Lesen Sie jetzt mehr
              über
              <router-link to="/heilpraktiker_zusatzversicherung_kuendigen">
                Heilpraktikerversicherung kündigen</router-link
              >

              <!-- end main  area left -->
            </v-col>

            <v-col cols="12" md="3">
              <h2>Debatte um Homöopathie Leistung der GKV</h2>
                In letzter Zeit steht die Abschaffung homöopathischer Leistungen
                der Gesetzlichen Krankenversicherung (GKV) vermehrt im
                Mittelpunkt. Das Team der DornsteinTabelle hat dazu ausgewählte
                Naturheilkunde-Experten nach deren Einschätzung zu einem
                <router-link to="/debatte_um_homoeopathie_leistung_der_gkv"
                  >möglichen Wegfall homöopathischer Leistungen
                  (Satzungsleistung GKV) befragt</router-link
                >.
                <br><br>
              <div id="kunden_ueber_dornsteintabelle"></div>
              <h2 class="mt-4 mb-2">Kunden über die DornsteinTabelle</h2>

              <b>EINE BEWUSSTE ENTSCHEIDUNG FÜR MEINE GESUNDHEIT</b><br />

              <span style="font-size: 11px"
                >Katja, 36 Jahre aus NRW: Barmenia Mehr Gesundheit 2.000</span
              >
              <br /><em>
                Ich freue mich darauf, mit der Versicherung einen für mich
                wesentliche Investition in meine eigene Gesundheit zu machen.
                Viele Grüße und herzlichen Dank für Ihre Unterstützung.</em
              >
              <v-divider class="my-4"></v-divider>
              <b>TROTZ VORERKRANKUNG EINE VERSICHERUNG GEFUNDEN</b><br />

              <span style="font-size: 11px"
                >Die kleine Paula, 1 Jahr und Mama Hannah, 28 Jahre aus Hamburg
                uniVersa uni-med|A-Exklusiv</span
              >
              <br /><em>
                Bei meiner Tochter wurde eine genetische Krankheit festgestellt,
                schon während der Schwangerschaft. Ihr geht es aktuell zum Glück
                wirklich gut und sie hat keine Einschränkungen. Wir müssen
                jedoch halt immer wieder ins Krankenhaus zur Kontrolle, zum
                Kinderarzt etc. Nach Rücksprache mit dem Kinderarzt hat er sein
                okay zu der Gesundheitsfrage gegeben, ihre Diagnose ist nicht
                aufgeführt. Dankeschön für Ihre Mühen, jetzt bin ich
                erleichtert.
              </em>
              <v-divider class="my-4"></v-divider>
              <b>MEIN WEG ZUM HEILPRAKTIKER DES VERTRAUENS</b><br />

              <span style="font-size: 11px"
                >Christoph, 42 Jahre aus München ARAG 483</span
              >
              <br /><em>
                Ja, meistens sind es ja die Frauen, die sich um
                Gesundheits-Themen kümmern. Meine Freundin hat mir einfach einen
                Naturheilkunde Tarif rausgesucht und mich überzeugt, künftig
                auch zu ihrer Heilpraktikerin zu gehen. Und ja, die
                Kräutertherapie tut mir richtig gut.</em
              >
              <v-divider class="my-4"></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";

  import { useSharedDataStore } from "@/stores/sharedData";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const common = useSharedDataStore();
  //const sharedDataStore = useSharedDataStore();
  import BaseCalls from "@/services/BaseCalls";
  const tarife = ref([]);
  async function getTarife() {
    let select = {};

    // if (props.tableTyp == "heilKinder") {
    //   select.action = "getStaticHeilTarife";
    //   select.select = props.tableTyp;
    //   select.addPreis = 6;
    // }

    select.action = "getStaticHeilTarife";
    select.select = "heilAlleStart";
    select.addPreis = 18;

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  const bilderUser = ref([
  {
      title:
        "Praxis Christel Heidinger in Gräfenberg",
      image: "Praxis Christel Heidinger.png",
    }, 
     {
    title: "Danai Dürselen, Praxis für heilkundliche Psychotherapie",
    image: "Praxis Danai Dürselen.jpg",
  }, 
  {
      title:
        "Praxis für Chinesische Medizin und Akupunktur Lynda Wieloch in Berlin",
      image: "Praxis Lynda Wieloch.png",
    },
    {
      title: "Heilquartier Claudia Meixner in Gelsenkirchen",
      image: "Praxis Claudia Meixner.png",
    },
    {
      title: "Privatpraxis Claudia Erhard in Villingen-Schwenningen",
      image: "Praxis Claudia Erhard.png",
    },
    {
      title:
        "Heilpraxis Vitalis Vistara Annette Habel in Schrozberg-Bartenstein",
      image: "Praxs Vistara Annette Habel.png",
    },
    {
      title:
        "Praxis für Körperpsychotherapie, Yoga und Gesundheitstraining Kerstin Löwenstein in Bad Honnef",
      image: "Praxis Kerstin Löwenstein.png",
    },
    {
      title: "Naturheilpraxis Kerstin Reichl in Deggingen",
      image: "Praxis Kerstin Reichl.png",
    },
    {
      title: "Heilpraktiker Praxis Münster Iris Kreuzheck-Koch",
      image: "Praxis Iris Kreuzheck-Koch.png",
    },
    {
      title: "Praxis Andrea Gabriele Vollrath in Simmern (Hunsrück)",
      image: "Praxis Andrea Gabriele Vollrath.png",
    },
    {
      title: "Privatpraxis Dr. h.c. Heiko Wegener in Hilden",
      image: "Praxis Dr. h.c. Heiko Wegener.png",
    },
    {
      title: "Heilpraxis Nicole Kubillus in Berlin",
      image: "Praxis Nicole Kubillus.png",
    },
    {
      title: "Praxis JETMED Nina Louise Jethwa in Ludwigsburg",
      image: "Praxis Nina Louise Jethwa.png",
    },
    {
      title:
        "Naturheilpraxis für Körper-Geist-Seele Elke Nilssen in Nonnenhorn",
      image: "Praxis Elke Nilssen.png",
    },
    {
      title: "Bayreuther Naturheilpraxis Susanne Höllbacher",
      image: "Praxis Susanne Höllbacher.png",
    },
    {
      title:
        "Heilpraktikerin für Psychotherapie Sabine Hornig in Limburg an der Lahn",
      image: "Praxis Sabine Hornig.png",
    },
    {
      title: "Naturheilpraxis Ingrid Behr in Weyhe",
      image: "Praxis Ingrid Behr.png",
    },
    {
      title: "Praxis inbalancesein Petra Kohlmann in Cottbus",
      image: "Praxis Petra Kohlmann.png",
    },
    {
      title:
        "Praxis Jutta Göldner Gestalttherapeutin Heilpraktikerin für Psychotherapie in Hersbruck",
      image: "Praxis Jutta Göldner.png",
    },
    {
      title: "Praxis Benjamin Hartlieb in Pforzheim",
      image: "Praxis Benjamin Hartlieb.png",
    },
    // {
    //   title: "Bayreuther Naturheilpraxis Susanne Höllbacher",
    //   image: "Praxis Susanne Höllbacher.png",
    // },
    // {
    //   title:
    //     "Heilpraktikerin für Psychotherapie Sabine Hornig in Limburg an der Lahn",
    //   image: "Praxis Sabine Hornig.png",
    // },
    // {
    //   title: "Naturheilpraxis Ingrid Behr in Weyhe",
    //   image: "Praxis Ingrid Behr.png",
    // },
    // {
    //   title: "Praxis inbalancesein Petra Kohlmann in Cottbus",
    //   image: "Praxis Petra Kohlmann.png",
    // },
  ]);
  //console.log(bilderUser.value);
  function getImagePath(imageName) {
    return require(`@/assets/user/${imageName}`);
  }

  const highlightStart = ref({
    flexbudget: "Flexibles Versicherungsbudget",
    ohnewarten: "Keine Wartezeit",
    zwoelfer: "nur 12 Monate Vertragslaufzeit",
    psycho: "Leistung für Psychotherapie",
    sehen: "Leistungen für Brille & Kontaktlinsen",
    lasik: "Leistungen für LASIK",
    ausland: "Leistung bei Auslandsreisen",
    kinder: "Kinder-Tarif",
    cashback: "Garantierte Beitragsrückerstattung bei Leistungsfreiheit",
    gesund: "Vereinfachte Gesundheitsprüfung",
    natur: "reiner Naturheilkunde-Tarif",
    auslandonly: "Auslandsreisekrankenversicherung",
    zahn: "Leistungen für Zahnprophylaxe, Bleaching sowie Wurzel- und Parodontosebehandlung",
  });
  function showHighlight(hp_highlight_fields) {
    let result = "";
    if (!hp_highlight_fields) {
      return result; // Frühzeitige Rückgabe, wenn hp_highlight_fields null oder undefined ist
    }
    let worte = hp_highlight_fields.split(",");
    worte.forEach((wort) => {
      if (highlightStart.value && highlightStart.value[wort]) {
        result = result + "<li> " + highlightStart.value[wort] + "</li>";
      }
    });
    return result;
  }
  const scrollMeTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  // function scrollMeTo(refName) {
  //   var element = this.$refs[refName];
  //   var top = element.offsetTop - 50;
  //   window.scrollTo(0, top);
  // }
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }

  onMounted(() => {
    saveSeo(1, 1);
    getTarife();
    berechnungStore.setShowFooter(true);
    const { proxy } = getCurrentInstance();
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherung Vergleich | Die Top Tarife " + common.getAktuellesJahr(),
      "Jetzt die besten Heilpraktikerversicherungen online vergleichen, mit DornsteinTabelle. Von Heilpraktikern empfohlen."
    );
  });
</script>
<style scoped>
  .title-container {
    padding: 8px; /* Fügt innere Padding hinzu */
    width: 100%; /* Stellt sicher, dass der Container die gesamte Breite des Bildes einnimmt */
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Dunkler Hintergrund für bessere Lesbarkeit */
    box-sizing: border-box; /* Berücksichtigt Padding in der Breite */
  }

  .v-card-title {
    white-space: normal; /* Erlaubt Textumbrüche */
    overflow: visible; /* Lässt den Text überfließen */
    text-overflow: clip; /* Verhindert Ellipsen */
  }
</style>
