<template>
  <v-container>

    <v-row class="mb-6">
      <v-col cols="10">
       <h1>Mit Herz und Erfahrung: Die naturheilkundliche Reiseapotheke</h1> 

24 Heilpraktikerinnen und Heilpraktiker haben ihre bewährten Empfehlungen vielfach erprobt, mit Liebe für Patienten zusammengestellt und sorgsam für DornsteinTabelle ausgewählt. Für alle, die auch unterwegs natürlich gut versorgt sein möchten. Echte Experten-Tipps die Gold wert sind, braucht man erprobte Empfehlungen für die eigene Naturheilkunde Reiseapotheke.

<br>
<h2>Hinweis vorab – weil Naturheilkunde individuell ist:</h2>


Jeder Mensch ist anders, reist anders – und so unterschiedlich wie die Reiseziele und Lebensumstände, so individuell sind auch die Bedürfnisse unterwegs. 
Unsere Heilpraktikerinnen und Heilpraktiker haben naturheilkundliche Mittel sorgsam zusammengetragen, die sich in der Praxis bewährt und ihren Patienten geholfen haben.
Natürlich ersetzt diese Liste keine persönliche Beratung – und nicht jedes Mittel passt zu jedem. Bitte treffen Sie die Auswahl am besten in Absprache mit Ihrem Heilpraktiker, Behandler oder Therapeuten – und nutzen Sie die Empfehlungen als Anregung, Ihre ganz eigene Reiseapotheke mit Bedacht zusammenzustellen. 
Es wird kein Anspruch auf Vollständigkeit erhoben.

      </v-col>
    </v-row>
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Susanne Willkomm, Heilkundepraxis in Lamspringe</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine Naturheilkunde-Reiseapotheke Tipps:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Meine Erkältungstipps: Ich empfehle toxiLoges Infekt Tropfen ( bitte Kontraindikationen und Dosierung beachten), sowie PlasmaLiquid Nasensprühgel und bei Halsschmerzen die Plasmaliquid Mundspüllösung.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Zusätzlich hoch dosiertes Vitamin C (zB Vitapas C liposomal von Pascoe) und Zink (zB Unizink) . Präventiv La Vita Saft täglich zB als 3 Monats Kur vor den üblichen Erkältungszeiten.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
    <!-- anfang -->
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
    <v-card-item>
       <v-card-title>Empfehlungen von Iris Mosel, Naturheilpraxis in Scheeßel</v-card-title>
       <v-card-subtitle class="mt-2 font-weight-bold">
         Folgende Mittel haben sich bei meinen Patienten bewährt. Dosierungen sind  individuell festzulegen:
       </v-card-subtitle>
       <v-card-text>
         <v-list>
           <v-list-item density="compact">
             <v-list-item-title>
               <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Erkältung: Metavirulent von der Firma metaFackler, DHU Schüssler Salze Nr. 3 Ferrum Phosphoricum D12  und Kalium chloratum in D6.
             </v-list-item-title>
           </v-list-item>
           <v-list-item density="compact">
             <v-list-item-title>
               <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Erschöpfung und Streß: DHU Schüssler Salze Nr. 5 Kalium phosphoricum D6  und Nr. 7 Magnesium phosphoricum D6, Rhodiola.
             </v-list-item-title>
           </v-list-item>
<v-list-item density="compact">
             <v-list-item-title>
               <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Insektenstiche: DHU Apis D6.
             </v-list-item-title>
           </v-list-item>
 <v-list-item density="compact">
             <v-list-item-title>
               <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Magen Beschwerden: DHU Nux vomica in D6.
             </v-list-item-title>
           </v-list-item>
 <v-list-item density="compact">
             <v-list-item-title>
               <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Zerrungen und Verstauchung nach Verletzung: Traumeel von der Firma
Heel als Tablette und Salbe.
             </v-list-item-title>
           </v-list-item>
          </v-list>
       </v-card-text>
     </v-card-item>
    </v-card>
        <!-- ende -->
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Kirsten Sander, Praxis für Gesundheit und Entspannung in Taunusstein-Hahn</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meines Erachtens nach sollte man auf Reisen immer dabei haben:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Metavirulent gegen Infekte, gerne auch Belladonna, Aconitum, Ferrum phos.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Okoubaka bei Magen-Darm-Beschwerden + Omni Biotic Reise, Nux Vomica
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Insektenstiche: Apis
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Cantharis - Sonnenbrand/Blasenentzündung
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Rhus tox - Muskel/Gelenkschmerz
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Arnika - Prellungen/Blutergüsse
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Akupunktur gegen Stress/Angst - Seekrankheit und zusätzlich Ingwer
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Verletzungen der Haut: Rescue Creme / Regena plex Haut fluid
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Carola Pickenhan, Heilpraxis "werdefrei" in Scheidegg</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine persönliche Naturheilkunde-Reiseapotheke:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erschöpfung: Vitamin B komplex und Omega 3 Fettsäure, Smoothi (also Vitamin + Mineralstoffdrink)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei einem Insektenstich: Apis C 30, Homöapathie, ergänzend Fenistil-Gel
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Zerrungen: Kühlen: Sofort mit Eis oder kaltem Umschlag kühlen (max. 15-20 Min.). Arnika: Als Salbe oder Globuli (z. B. Arnica D6 oder D12) hilft gegen Schwellungen und Schmerzen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei einem Sonnenbrand: Aloe Vera: Frisches Aloe-Vera-Gel kühlt und beruhigt die Haut. Johanniskrautöl: Wirkt entzündungshemmend, aber erst nach dem Sonnenbad anwenden (macht lichtempfindlich!). Apfelessig: Mit Wasser verdünnt als Umschlag, beruhigt die Haut. Globuli: Cantharis D6 hilft bei starker Rötung und Blasenbildung.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Sonnenstich: Kühlen: Kalte Tücher auf Kopf und Nacken legen. Ruhe & Schatten: Unbedingt aus der Sonne gehen und viel Wasser trinken. Belladonna D6 oder D12: Bei starkem Kopfweh, rotem Gesicht und Fieber.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Magen-Darm-Problemen: Heilerde oder Aktivkohle: Bindet Giftstoffe bei Durchfall. Kamillen- oder Fencheltee: Beruhigt Magen und Darm. Ingwer: Hilft gegen Übelkeit, als Tee oder roh gekaut.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Übelkeit & Völlegefühl: Nux vomica D6/D12, Okoubaka D6 bei Magen-Darm-Infekten, Arsenicum album D6 bei Durchfall durch verdorbenes Essen
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei einer Erkältung: Ingwer-Zitronen-Tee mit Honig: Stärkt das Immunsystem. Zwiebelsaft: Mit Honig vermischt als Hustensaft. Salzwasser-Nasenspülung: Reinigt und befeuchtet die Schleimhäute.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei plötzlichem Fieberbeginn: Globuli - Aconitum D6, Belladonna D6 bei Fieber mit rotem Gesicht Pulsatilla D6 bei verschleimter Erkältung
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Im Falle einer Seekrankheit: Cocculus D6: In der Homöopathie das Mittel der Wahl gegen Schwindel & Übelkeit. Frische Luft & Horizont fixieren: Hilft dem Gleichgewichtssinn.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Schürfwunden: Ringelblumensalbe: Fördert die Wundheilung und wirkt entzündungshemmend. Arnika oder Calendula D6: Unterstützt die Heilung. Salzwasser-Spülung: Reinigt die Wunde auf natürliche Weise.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Stress: Lavendel: Lavendelöl oder Tee beruhigt das Nervensystem. Bachblüten-Rescue-Tropfen: Bewährt bei Stresssituationen. Homöopathie: Nux vomica D6 bei Reizbarkeit, Ignatia D6 bei emotionalem Stress, Gelsemium D6 bei Lampenfieber und Angst
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Dr. Silke von Huene, Homöopathische Praxis in Neustadt an der Weinstraße</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine Tipps für unterwegs:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Fix und fertig wo ankommen (bspw. auch im Urlaub, Hinflug, spätabends in der Unterkunft): Nux vomica C 30 bringt runter und gibt einen guten Schlaf.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Am nächsten Morgen Kalium carbonicum C30 als Stärkungsmittel.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Annette Riederer von Paar, Naturheilpraxis in Alling und Germering</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine persönlichen Reiseapothekentipps:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Halsschmerzen & Blasenentzündung: ANGOCIN (Fa. Repha) - Im Akutfall 5 x 5 Stk. pro Tag - anschließend herabdosieren bis 3 x 3 Stk. tgl.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei nahender Erkältung: Erkältungs-Entoxis-Tropfen (Fa. Spenglersan)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Kopfschmerzen: Rephalgin (Fa. Repha) - Im Akutfall 6 x 1 Stk. pro Tag
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Schnupfen: Sinusitis Hevert - Im Akutfall 6 x 1 Stk. pro Tag
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Schmerzen, Muskelverspannung, Mückenstiche, verstopfte Nase: China-Öl (mit Nasen-Inhalator)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Obstipation: DHU Ocubacca D12 - Globuli - Im Akutfall 5 x 5 Stk. pro Tag
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Durchfall: Perenterol forte Kapseln („Junior"-Pulver für Kinder) nach Packungsanleitung
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Schlafstörungen: Baldrian-Dragee 1 Stk. +PLUS+ Neurexan-Tabletten (Fa. Heel) - 2 x 1 Stk. vor dem Schlafengehen
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Ohrenschmerzen: Otovoven-Tropfen: Bei akuten Zuständen alle halbe bis ganze Stunde, höchstens 12 x täglich 15 Tropfen ORAL einnehmen!
              </v-list-item-title>
            </v-list-item>
          </v-list>
         
          <p class="mt-4 font-italic">Was Sie sonst noch für Ihre Gesundheit tun können:</p>
         
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Viel Schlafen: Schlafen Sie sich gesund. Körperliche Ruhe hilft Ihrem Abwehrsystem die eingedrungenen Krankheitserreger wirksam zu bekämpfen. Meiden Sie extreme Temperaturwechsel. Während einer fieberhaften Erkrankung wird Ihr Körper hierdurch geschwächt.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Viel Trinken: Trinken Sie 2‑3 Liter warmen Tee über den Tag verteilt, um die Schleimhäute zu befeuchten. Traditionell wird auch heiße Hühnerbrühe bei Erkältungen empfohlen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Gut Lüften: Sorgen Sie für gut durchlüftete Räume mit ausreichender Luftfeuchtigkeit. Stellen Sie hierfür Schalen mit Wasser und einigen Tropfen Minz- oder Eukalyptusöl oder einigen Orangen- oder Zitronenschalen auf.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Die Schleimhäute feucht halten: Gurgeln mit Salbei-, Kamillentee oder Kochsalzlösung (1 gehäufter Teelöffel auf 500 ml lauwarmes, zuvor abgekochtes Trinkwasser) beruhigt den Hals und wirkt entzündungshemmend. Mögliche Nasenspülungen mit dieser Kochsalzlösung beschleunigen die Genesung.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Anna Hertl, Naturheilpraxis in Mühldorf am Inn</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Gerne gebe ich meine Tipps weiter:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Husten: Luft befeuchten, vor allem nachts (feuchte Tücher ins Schlafzimmer oder elektrischer Luftbefeuchter)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: Angocin (Repha) / Propolis / japanisches Heilpflanzenöl
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Herpes: Propolis gleich bei Bemerken auftupfen
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Warzen: Teebaumöl
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Insektenstich: Heat it - Stick
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Verdauungsbeschwerden (je nach Befund): z.B.Synerga (Laves) bei Entzündung der Darmschleimhaut oder Myrrhinil Intest (Repha)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei stumpfen Traumata: Traumeel Salbe (Heel)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Entzündung der Mundschleimhaut / Aphten: Infectogini Gel
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Gabriela Schülke, Naturheilpraxis in Hemau</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Was tun, bei den ersten Anzeichen einer Erkältung?:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: Metavirulent Tropfen, Fa. Meta Fackler bei ersten Anzeichen einer Erkältung. Sofort ca . 15 - 18 Tropfen nehmen, 1-2 Stunden später nochmals. Tropfen mindestens eine Minute gerne länger im Mund lassen.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Eine Empfehlung von Julius Etourno, Heilpraktiker in Freiberg</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Was mit persönlich gut hilft:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: metavirulent, bei den ersten Anzeichen einer Erkältung sofort 10-30 Tropfen nehmen. Vorm Schlafen nochmals. Hat sich bei mir nun seit längerer Zeit bewährt
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Claudia Meixner, Heilquartier in Gelsenkirchen</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine persönlichen Empfehlungen:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: Metavirulent im akuten Fall stündlich 10 Tropfen, Metatussolvent ebenfalls im akuten Fall stündlich 10 Tropfen, bei Besserung die Dosis reduzieren auf 5x bzw 3x täglich.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Zerrungen: Umschläge mit Retterspitz äußerlich.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Insektenstichen: hier lindert 1-2 Tropfen Lavendelöl direkt auf die Haut aufgetragen sehr schnell den Juckreiz.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Sonnenbrand: Quarkauflagen, hierfür wird der zimmerwarme Quark messerdick auf ein Zewa aufgetragen und auf die Haut gelegt, mit einem Tuch abdecken und nach circa 15 Minuten wieder abnehmen.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Sabine Carbone, Naturheilpraxis Traditionelle Ayurvedische Medizin in Augsburg</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Anbei meine Liste zu bewährten Mitteln bei verschiedenen
 Erkrankungen. Wichtig: Die Mittelauswahl und Dosierung unbedingt in die Hände von erfahrenen Therapeuten!
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: Nux vomica
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei einem Sonnenstich: Belladonna, Kühlung mit Sandelholz und Minzöl, Minztee
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Sonnenbrand: Aloe vera Gel, Cantharis (bei Blasenbildung)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei einem Insektenstich: Apis
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Zerrungen: Arnica, Bryonia
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Stress: Nux vomica
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Seekrankheit: Ingwer+Honig+Zitronensaft
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Magen-Darm-Beschwerden: Pfefferminztee, Triphala, Amalaki
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Kreislaufproblemen: Rosmarintee, Ingwertee
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Ursel Carls, Naturheilpraxis in Hamburg</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Anbei ein paar Empfehlungen von mir:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                1. Mittel bei SONNENSTICH ( hämmernde Kopfschmerzen, der Herzschlag pocht unter der Schädeldecke = Belladonna C30, kommt noch Benommenheit hinzu, dann 2.Mittel = Glonoinum D30
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                INSEKTENSTICH ( Tierbisse jeder Art):= Ledum D12,  Geranium D30( bei Zeckenbiss)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                MAGEN/DARM (Reisedurchfall/Erbrechen)=OKOUBAKA D3, Nux vomica D12
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                SEKRANKHEIT: = Cocculus D6( auch bei Jet-Leg)
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                KREISLAUF SCHWÄCHE:Veratrum Album C30, bei sehr grosser Erschöpfung = Carbo vegetabilis C30
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                GRIPPALER INFEKT =Gelsemium D30
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
    <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Kathrin Meyer-Semercioglu, Naturheilpraxis im Auenviertel in Münster</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine wesentlichen Empfehlungen für die Reiseapotheke:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Für Magenbeschwerden Neo Bianscid, bei Durchfall LenoDiar, beides von der Firma Aboca.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung/Virusinfekte: Metavirulent.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Tobias Popp, Naturheilpraxis in Wunstorf</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Auszug meiner homöopathischen Reiseapotheke Empfehlungen für meine Patienten:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Cocculus: Für Beschwerden durch Reisen mit Bahn, dem Auto oder Schiff; durch Essensgeruch, Denken an Essen
     Bei Hauptbeschwerden wie: Übelkeit, Erbrechen, Parästhesie, Schwäche, Unruhe, Ängstlichkeit. Beschwerden durch Schlafmangel.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                rhus  toxicod.: Beschwerden durch Kälte, Nässe, naßkaltes Wetter, Verkühlen nach dem Schwitzen.Schnupfen, Halsschmerzen, Mandelentzündung, Husten.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                nux  vomica: Unklare Magen- Darmbeschwerden: Folge von Überessen, verdorbener Nahrung, zu fetter, schwerer Nahrung... Alkohol! Übelkeit, evt. Erbrechen, krampfhafte Magenschmerzen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                ledum pallustre: Hauptmittel bei Zeckenbiß- Borrelioseprophylaxe
     Wenn apis zu wenig geholfen hat. (siehe dort). Bei Überempfindlichkeit auf Insektenstiche.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                apis mellificia: Nach Bienen, Wespen, Hornissenstichen, oder anderen Tierstichen, oder Bissen, die wie ein Bienenstich aussehen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Cantharis: Bei Verbrennung. (Feuer, Sonne, Quallen und andere Gifte, die zu Blasenbildung führen. Erstes Mittel bei Blasenentzündung und Schmerzen beim Urinieren.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Barbara Weber-Köpke, Praxis 90 - Naturheilpraxis in Bergisch Gladbach </v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine Tipps fürs Reisen:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Metavirulent (3 Rage vor Reiseantritt beginnen bis einschließlich 3 Tage nach Ankunft).Schließt die immunologische Lücke zwischen „mit Vollgas die letzten Tage im Job" und „1. Urlaubstag, der Stress lässt nach und man wird krank".
              </v-list-item-title>
            </v-list-item>
           </v-list>
        </v-card-text>
      </v-card-item>  
    </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Claudia Katzberg, Naturheilpraxis in Handewitt</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Als Homöopathin kommt in meine Reiseapotheke:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Arnica bei Verletzungen, Prellungen, Stößen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Apis bei Insektenstichen.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Okoubaka bei Nahrungsmittelunverträglichkeiten/ Übelkeit/ Darmbeschwerden.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Arsenicum album bei großer Schwäche, starker Übelkeit, Erbrechen, Durchfall,Lebensmittelvergiftung.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Aconitum bei Schock oder Panik.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Ferrum phosphoricum bei akuter Erkältung , Entzündung, Schmerzen oder Sonnenbrand.
              </v-list-item-title>
            </v-list-item>
          </v-list>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Magnesium phosphoricum bei Stress, Schlafproblemen, Muskelverkrampfungen.
              </v-list-item-title>
            </v-list-item><v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Nux vomica bei Jetlag, Stress, Alkohol, Überessen, Verdauungsbeschwerden.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Natrium carbonicum bei Sonnenstich/ Folgen von Sonne.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Ignatia bei Heimweh.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Info: C30 ist eine bewährte Potenz. Ein Globulus zur Zeit, so oft wie nötig. 1- max. 3 mal täglich.
              </v-list-item-title>
            </v-list-item>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Shenja Kerimov, Naturheilpraxis in Ludwigsfelde</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Bewährte Empfehlungen aus meiner Praxiserfahrung:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Erkältung: Bei den ersten Anzeichen bewähren sich Produkte, die eine Mischung aus bestimmten Beta-Glucanen in Kombination mit Vitamin C und Zink enthalten.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Sonnenbrand: AloeVera.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Schürfwunden: eingeweichte Birkenblätter rauflegen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Magen-Darm-Beschwerden: Nahrungskarenz, Trinken sehr wichtig (nur in kleinen Schlucken und am besten schwarzer Tee), Nahrung langsam wieder aufbauen, am besten mit Nahrungsmitteln die nicht belasten und eine stopfende Wirkung haben, wie zum Beispiel Banane.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Kreislaufprobleme: in den Schatten, Hinlegen und Treppenlagerung, das heißt die Beine im rechten Winkel hochlagern, prophylaktisch viel trinken.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Stress: 4-7-8 Atemmethode (4 Sekunden einatmen, 7 Sekunden halten, 8 Sekunden ausatmen= das ganze 10 mal hintereinander), Produkte mit hochdosiertem Safran.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Margit Heiter, Naturheilpraxis in Laupheim</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Auszug meiner Hausapotheke, die ich auch an meine Patienten weiter gebe. Diese kann natürlich nach Vorlieben und Erfahrungen ausgewählt oder ergänzt werden:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung/ grippaler Infekt. ERSTE WAHL: Kapuzinerkresse-Meerrettichtinktur, zusätzlich 3x tägl. 1 Tasse Holunderblütentee. Alternativ Ceres, Urtinktur Tropaeolum majus (Kapuzinnerkresse).
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Schmerzen allgemein: Schüssler Salze Nr. 7: als heiße 7, auch gut zum besser einschlafen: 10 Tabletten des Schüßler Salzes Nr. 7 in 0,2 Liter abgekochtem, heißen Wasser auflösen. Die heiße Lösung in kleinen Schlucken trinken. Dabei jeden Schluck kurz im Mund lassen.
 Ätherisches Lavendelöl:
 - hat ein sehr großes Wirkungsspektrum und kann pur aufgetragen werden.
 - es wirkt entzündungshemmend, blutstillend, wundheilend, zellerneuernd, beruhigend
 - bei Verbrennungen, Schnittwunden, Kopfschmerzen, Sonnenbrand.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Zahnschmerzen: Johanniskraut Urtinktur (Ceres Hypericum )
 3x tägl. 5 Tropfen in wenig Wasser. 1-2 Minuten im Mund spülen, dann schlucken. Oder 1 Gewürznelke „anbeißen", sodass äther. Öl austritt, dann in die Wangentasche an den schmerzhaften Zahn legen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Sonnenbrand: Combudoron Gel von Weleda
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Magen-Darm-Infekt/ Übelkeit: Okoubaka Globuli oder Kamillentee, Schwarztee, Fenchel-Kümmel-Anis Tee.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Innerlich vorbeugend: Vitamin-D Spiegel zwischen 70 – 80 µg/l.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Michael Münch, Münch Naturheilkunde in Neufahrn und München-Moosach</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Unser Wissen & Erfahrung für die Naturheilkunde-Reiseapotheke:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Insekten- und Zeckenabwehr: Amoskan Körperöl.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
               Bei Insektenstich: Zwiebel anschneiden und auflegen oder etwas einreiben, Ledum C30, Monolux Pen bestrahlen.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Prellung, Blutung: Arnika C30.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Schürfwunden: Calendula Tinktur optimal ist aufsprühen, Monolux Pen bestrahlen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Durchfall: Myrrhinil Iintest.
              </v-list-item-title>
            </v-list-item>
           </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Antje Schwan, Praxis für Naturheilkunde in Berlin</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Mein ultimativer Tipp Tipp für die Reiseapotheke ist Luvos Heilerde als Pulver:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Luvos Heilerde als Pulver kann bei Insektenstichen, Pickeln und Hautreizungen angewendet werden.
 Außerdem ist sie hilfreich bei Magen-Darm-Infekten, Magenverstimmung, Sodbrennen und Übelkeit bei Mensch und Tier.
 Heilerde angerührt mit kaltem Wasser wirkt lindernd bei Sonnenbrand.
 Das ist nur ein kleiner Ausschnitt der Möglichkeiten. Von daher ist Heilerde immer in meiner Haus- und Reiseapotheke vorrätig.
              </v-list-item-title>
            </v-list-item>
           </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Andrea Maninger, Heilpraktikerin in Tauberbischofsheim</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine Erfahrungen für eine Reiseapotheke:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erkältung: Dr. Pandalis Cystus Bio Halstabletten; ToxiLoges Tropfen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Sonnenbrand: Cution spag. Peka Lotion.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Erschöpfung: Dr. Koll Nerven Plus.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Insektenstich: Dr. Pandalis Cystus Bio Salbe.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Magen-Darm-Beschwerden: Spenglersan Magen-Darm-Entoxin.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Stress: Hevert Calmvalera Tropfen.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Jörg Pantel, Heilpraktiker in Münster</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Auszug meiner homöopathischen Reiseapotheke, die ich vor einigen Jahren für meine Patienten zusammengestellt habe:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Blasenentzündung: Canth. D4 - Brennen in der Harnröhre, starke Schmerzen vor und nach dem Wasserlassen.
          </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Durchfall: Ars. D6 - Vergiftungserscheinungen mit brennenden Schmerzen und Kälte.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Entzündungen: Echi.0 / Echi. D1 - Bei allen Entzündungen von der Wundinfektion über Gelenkschmerz bis hin zur Grippe setzt man Echinacea ein. Dieses stärkt die Abwehrkräfte und da es relativ unspezifisch wirkt, wird es ungeachtet der besonderen Symptomatik bei allen Entzündungen zusätzlich gegeben.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Halsschmerzen: Lach. D12 - Schlucken von Flüssigkeit, morgens, linke Seite sowie Lyc. D12 rechte Seite.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Insektenstichen: Apis D30 - Schwellung, Röte, Hitze, stechender Schmerz, zusätzlich 10 Globuli in Wasser lösen und als Kompresse auf die Schwellung aufbringen.
              </v-list-item-title>
            </v-list-item>
  <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Bei Reisekrankheit: Cocc. D4 - Schwindel und Übelkeit durch Fahren im Auto, Schiff oder Bahn und Petr. D6 s.o. vor allem auf See Tab. D30
  Übelkeit, kalter Schweiß, Herzklopfen, aufgeregte Kinder.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Claudia Erhard, Praxis für Naturheilkunde und Physiotherapie in Villingen-Schwenningen</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Diese Haus- Reise- und Zeltlagerapotheke ist zwar nicht rein naturheilkundlich, dafür aber aus dem "richtigen Leben". Es ist genau das, was ich meiner Tochter zu mehreren Zeltlagern mitgegeben habe, sowohl die Anleitungsliste, als auch die Mittel, die sie in kleinen Döschen und Röhrchen dabei hatte. Inzwischen ist sie 17 Jahre alt und hat ganz nebenbei gelernt, sich selbst die jeweils passenden Mittel zu suchen:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Schüsslersalze: Wenn eine Erkältung kommt, ein Sonnenbrand da ist oder sonstige „Notfälle" eingetreten sind: Nr. 3
 Wenn Husten oder Schnupfen da ist: Nr. 4
 Bei Schwäche:  Nr. 5, eventuell Nr. 2.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                rhus  toxicod.: Beschwerden durch Kälte, Nässe, naßkaltes Wetter, Verkühlen nach dem Schwitzen.Schnupfen, Halsschmerzen, Mandelentzündung, Husten.
              </v-list-item-title>
            </v-list-item>
 <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Meine Globuli-Tipps:
          Bei jeder Art von Verletzung: Arnica
          Bei Verbrennung (wie auch Sonnenbrand): Cantharis
          Bei Übelkeit, Bauchweh und Niesen: Nux vomica
          Bei laufender Nase: Allium cepa
          Bei Halsweh und trockenem Husten: Spongia
          Bei Husten: Drosera
          Bei verstopfter Nase: Sambucus Nigra
              </v-list-item-title>
            </v-list-item>
           </v-list>
        </v-card-text>
      </v-card-item>
   </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Angelika Luck-von Hassel, Ganzheitliche Naturheilpraxis in Hamburg </v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Meine bewährten Empfehlungen. Allerdings muss man bei manchen Themen einfach individuell und tiefer schauen, wo es herkommt:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Erkältung:
         - Bei den ersten Anzeichen und darüber hinaus > Metavirulent
         - Trockener Husten/Hustenreiz > Metatussolvent
         - Halsschmerzen, Rachenraumentzündung > Angina-Gastreu S
         - Husten > Hustensaft Prospan für Kinder
         - Husten/Erkältung > Zwiebelsaft (aus Zucker und Zwiebeln)
         - Erkältung > Metaglobiflu von Meta Fackler
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Erschöpfung/Stress:
            > Metakaveron
            > Nebennieren stärken mit Phytocortal N. Allerdings braucht es hier erst mal eine ordentliche Ursachenforschung
            > RhodioLoges bei Müdigkeit und Erschöpfung
            > Body 2 Brain Körperübungen
            > Achtsamkeitsschulung
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Sonnenbrand/Insektenstich:
            > frische Aloe Vera, Kokosöl
            > Combudoron von Weleda
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Kreislaufprobleme:
            > Skorodit Kreislauf Globuli velati von WALA
            > Weißdorn stärkt den Kreislauf (Tee)
            > Akupressur NN13
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Magen/Darmbeschwerden:
                > Myrrhinil-Intest
                > Kohletabletten
                > Heilerde
                > Probiotika
                > Fenchel-, Kümmel-, Anis- und Kamillentee
                > Bitterstoffe
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Zerrungen:
                > Arnika Salbe und Globuli
                > Retterspitz Umschläge
                > Kühlen
                > Traumeel
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Schürfwunden:
                > Calcea Wund- und Heilcreme von WALA
                > Calendula Wundsalbe von WELEDA
                > Wundsalbe mit Manuka-Honig
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
 
 
 <v-card
      class="mb-6"
      max-width="900"
      elevation="2"
    >
      <v-card-item>
        <v-card-title>Empfehlungen von Sophia Schwab, unique connect by Artur Müller in Wangen im Allgäu und Birkenhard bei Biberach</v-card-title>
        <v-card-subtitle class="mt-2 font-weight-bold">
          Auszug unsere Reiseapotheke mit wertvollen Tipps für unterwegs:
        </v-card-subtitle>
       
        <v-card-text>
          <v-list>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Durchfallmittel: 
  - Combi Flora Akut, zur Vorbeugung vor der Reise schon beginnen.
  - Zeolith MED Detox-Pulver, 2-3 mal täglich je 2 bis 4 Kapseln
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Abführmittel:
  - Reise-Irrigator, nach Bedarf durchführen
  - Magnesiumcitrat ab 200 mg
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Sodbrennen:
  - Kaiser Natron, halben Teelöffel in ein Glas Wasser gerührt
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Reiseübelkeit:
  - Ingwer, Möglichst bereits mehrere Tage vor der Reise oder unmittelbar bei Reiseantritt mit der Einnahme beginnen täglich etwa zwei Gramm frischen Ingwer verzehren oder als Aufguss in einer heißen Tasse Wasser und in einer Thermosflasche mitnehmen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Heilsalben und Desinfektionsmittel für Wunden:
  - Kolloidales Silber als Salbe oder Spray, nach Bedarf mehrmals täglich auf die Haut aufgetragen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Schmerzen und Fieber:
  - PEA 600 Kapseln 2 x tgl. 1 Kapsel zur Mahlzeit.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Kreislaufprobleme:
  - KORODIN Herz-Kreislauf-Tropfen 3 x tgl. 10 Tropfen.
              </v-list-item-title>
            </v-list-item>
            <v-list-item density="compact">
              <v-list-item-title>
                <v-icon start icon="mdi-circle-small" color="primary"></v-icon>
                Rückenschmerzen (auch Knie, Ellenbogen etc.):
  - PAIN Relief Patch auf die betroffene Stelle aufkleben.
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card-item>
    </v-card>
  </v-container>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  import BaseCalls from "@/services/BaseCalls";
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect);
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 41);
    proxy.$updateDocumentHead(
      "Naturheilkunde-Reiseapotheke – Tipps von 24 Heilpraktikern erhalten",
      "Jetzt Tipps von 24 erfahrenen Heilpraktikern erhalten, was in eine naturheilkundliche Reiseapotheke gehört. Praktische Empfehlungen für gesundes Reisen mit Naturheilkunde."
    );
  });
</script>

<style scoped>
.v-list-item-title {
  white-space: normal !important;
  line-height: 1.5 !important;
}

.v-card {
  transition: transform 0.2s;
}

.v-card:hover {
  transform: translateY(-2px);
}
</style>
 
 
 