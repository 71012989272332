<template>
  <v-app id="app" class="ggfont">
    <LoaderOverlay />

    <ErrorOverlay />

    <v-app-bar
      flat
      app
      clipped-left
      style="border-bottom: 1px solid silver; background-color: #fff"
    >
      <v-app-bar-nav-icon @click="showMenue = !showMenue"></v-app-bar-nav-icon>

      <div class="pt-0">
        <h1>
          <a href="/" class="redtext ggfont"
            >DornsteinTabelle
            <span
              v-if="sharedDataStore.isDesktop()"
              style="font-weight: normal; font-size: 22px"
              class="greytext"
              >&nbsp;&nbsp;Von Heilpraktikern empfohlen</span
            >
          </a>
        </h1>
      </div>

      <!-- <app-bar :key="componentKey" @clicked="reloadLogout" v-if="!isMobile" /> -->
    </v-app-bar>
    <v-row
      v-if="sharedDataStore.isDesktop()"
      style="
        position: fixed;
        margin-top: 65px;
        z-index: 1000;
        background-color: #fff;
      "
    >
      <v-col cols="12" md="12"
        ><router-link to="/" class="headerlink">Home</router-link>
        <span class="redtext">|</span>
        <router-link to="/vergleich/starten/heilpraktiker" class="headerlink"
          >Vergleich starten</router-link
        >
        <span class="redtext">|</span>
        <router-link
          to="/heilpraktikerversicherung_kind_vergleich"
          class="headerlink"
          >Heilpraktikerversicherung für Kinder</router-link
        >
        <span class="redtext">|</span>
        <router-link to="/realwert_heilpraktikerversicherung" class="headerlink"
          >4-Jahres-Realwert-Methode</router-link
        >

        <span class="redtext">|</span>
        <router-link
          v-if="user.user_id > 0"
          to="/kostenlos/anfordern"
          class="headerlink"
          >Jetzt <b class="redtext">DornsteinTabelle bestellen</b></router-link
        >
        <router-link v-if="!user.user_id" to="/heilpraktiker" class="headerlink"
          >Für Heilpraktiker:
          <b class="redtext">DornsteinTabelle bestellen</b></router-link
        >
        <span class="redtext">|</span>
        <router-link to="/ueber_mich" class="headerlink">Über mich</router-link>

        <span class="redtext">|</span>
        <span
          v-if="!user.user_id"
          @click="showLogin = !showLogin"
          class="headerlink"
          >Login
        </span>
        <span v-if="user.user_id > 0" @click="startLogout" class="headerlink"
          >Logout
        </span>
      </v-col>
    </v-row>

    <v-main
      class="d-flex align-center justify-center"
      style="max-width: 1400px"
    >
      <v-container
        fluid
        class="white pa-0"
        :style="{ marginTop: containerHeight + 'px' }"
      >
        <v-alert
          v-if="showNotAvaiable"
          type="error"
          style="height: 200px; padding-left: 100px"
        >
          <h1 class="text-white">
            Wir haben derzeit ein technisches Problem - bitte besuchen Sie uns
            in 3-4 Stunden noch einmal
          </h1>
          <p class="text-white">
            Alle 1-2 Jahre haben wir ein technisches Problem, dass die Funktion
            der WaizmannTabelle einschränkt. Das sind die stressigsten Stunden
            des Jahres. Jetzt ist es einmal wieder so weit. In den letzten 25
            Jahren haben wir es es immer geschafft in wenigen Stunden wieder
            online zu sein. Drücken Sie uns die Daumen und besuchen Sie uns
            etwas später wieder ;-)
          </p>
        </v-alert>

        <v-row class="ml-4">
          <v-col
            cols="12"
            md="2"
            class="mt-6"
            v-if="!sharedDataStore.isIpadAndBelow() && stopMenue"
            >&nbsp;</v-col
          >
          <v-col
            cols="12"
            md="2"
            class="mt-6"
            v-if="
              (!sharedDataStore.isIpadAndBelow() || showMenue) && !stopMenue
            "
          >
            <MenueNeu
              class="mt-12 pr-4"
              v-if="
                showMenue &&
                !berechnungStore.berechnung &&
                sharedDataStore.isDesktop()
              "
              style="
                position: fixed;
                top: 100px;
                width: 180px;
                border-right: 2px solid #f2f2f2;
                min-height: 600px;
              "
            />
            <MenueNeu
              class="mt-12"
              v-if="
                showMenue &&
                !berechnungStore.berechnung &&
                !sharedDataStore.isDesktop()
              "
              :berechnung="berechnungStore.berechnung"
            />
            <MenueRechner
              class="mt-12"
              style="position: fixed; top: 100px; width: 180px"
              v-if="
                showMenue &&
                berechnungStore.berechnung &&
                sharedDataStore.isDesktop()
              "
              :berechnung="berechnungStore.berechnung"
            />
            <MenueRechner
              class="mt-12"
              v-if="
                showMenue &&
                berechnungStore.berechnung &&
                !sharedDataStore.isDesktop()
              "
              :berechnung="berechnungStore.berechnung"
            />
          </v-col>
          <v-col cols="12" md="10">
            <router-view style="background-color: #fff; margin-top: 10px" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <div v-if="berechnungStore.showFooter" class="fixedFooter">
      <router-link to="/impressum">Impressum DornsteinTabelle</router-link>
    </div>
    <v-dialog v-model="showLogin" width="888">
      <LoginDialog />
    </v-dialog>
    <v-dialog v-model="berechnungStore.showPopApp" width="888">
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn icon @click="berechnungStore.closePopApp">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <AlleBerechnungen
          v-if="berechnungStore.showPopAppType == 'alleberechnungen'"
        />

        <TarifOptions
          :tarif="berechnungStore.tarif"
          v-if="berechnungStore.showPopAppType == 'showTarifOptions'"
        />
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script setup>
  import { ref, onMounted, nextTick, watch, computed } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useGuestStore } from "@/stores/guest";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useUserStore } from "@/stores/user";
  import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  import ErrorOverlay from "@/components/base/ErrorOverlay.vue";
  import AlleBerechnungen from "@/components/berechnung/vergleich/AlleBerechnungen";
  import MenueRechner from "@/components/berechnung/MenueRechner";
  import MenueNeu from "@/components/berechnung/MenueNeu";
  import LoginDialog from "@/components/user/LoginDialog";
  import TarifOptions from "@/components/tarif/TarifOptions";
  // import ChatWindow from "@/components/chat/ChatWindow";
  import { useRoute, useRouter } from "vue-router";

  import { useLoaderStore } from "@/stores/loaderStore";

  //import { useRoute } from "vue-router";
  import BaseCalls from "@/services/BaseCalls";
  const userStore = useUserStore();
  const router = useRouter();
  const user = computed(() => userStore.userData);
  const loaderStore = useLoaderStore();
  // const route = useRoute();
  const route = useRoute();
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const guestStore = useGuestStore();

  // import MenueDefault from "@/components/site/MenueDefault.vue";

  // import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  // import SuccessOverlay from "@/components/base/SuccessOverlay.vue";

  const lastBerechnung = ref(false);
  // const showAppBar = ref(true);
  const showMenue = ref(true);
  const stopMenue = ref(false);
  const showLogin = ref(false);
  const showNotAvaiable = ref(false);
  const scrollVisible = ref(true);
  const styleSwitch = ref("margin-top:40px;"); // Verwenden Sie `const` für konsistente Reaktivität
  const containerHeight = ref("20");
  //const bearbeiter = computed(() => bearbeiterStore.bearbeiterData);
  // function setLoading(loading) {
  //   loaderStore.setLoader(loading);
  // }

  const contentHeight = ref(0);

  // Funktion, um die Höhe des Inhalts zu messen
  function measureContentHeight() {
    const contentElement = document.getElementById("app");
    if (contentElement) {
      return contentElement.offsetHeight;
    }
    return 0;
  }

  function sendHeightToParent(newHeight) {
    // window.parent.postMessage(
    //   {
    //     frameHeight: newHeight,
    //   },
    //   "*"
    // );
    console.log("height: " + newHeight);
  }
  // async function checkChat() {
  //   let berSelect = {};
  //   berSelect.action = "isChatOpen";
  //   try {
  //     const resp = await BaseCalls.postChat(berSelect); // Korrigiert, um .value zu verwenden
  //     if (resp.data && resp.data.chatOpen) {
  //       berechnungStore.setShowPopChatButton();
  //     } else {
  //       berechnungStore.closePopChatButton();
  //     }
  //   } catch (err) {
  //     console.log("error berechnung");
  //   }
  // }
  async function getLastBerechnung(guest_id) {
    let berSelect = {};
    berSelect.action = "getLastBerechnung";
    berSelect.ber_guest_id = guest_id;
    console.log("get berechnung " + berSelect.ber_guest_id);
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        lastBerechnung.value = resp.data.berechnung;
        berechnungStore.setBerechnung(resp.data.berechnung);
        console.log("last berechnung ");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function checkLinkReferrer() {
    console.log("checkLinkReferrer");
    var checkStringGoogle = document.referrer,
      substring1 = ".google";
    var isGoogleLink = checkStringGoogle.indexOf(substring1) !== -1;

    var checkStringWaizmann = document.referrer,
      substring2 = ".dornstein";
    var isWaizmannLink = checkStringWaizmann.indexOf(substring2) !== -1;

    var checkStringLocal = document.referrer,
      substring3 = "local";
    var isLocalLink = checkStringLocal.indexOf(substring3) !== -1;

    if (isGoogleLink) {
      console.log("google link");
      let guestSelect = {};
      guestSelect.action = "insertGoogleVisit";
      guestSelect.gg_site = window.location.href;
      try {
        await BaseCalls.postGuest(guestSelect);
      } catch (err) {
        console.log("error inserting Google visit");
      }
    }


    if (!isGoogleLink && !isWaizmannLink && !isLocalLink) {
      let guestSelect = {};
      guestSelect.action = "checkLinkReferrer";
      guestSelect.ref = document.referrer;
      try {
        await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  async function checkInsertGuest() {
    let guestSelect = {};
    guestSelect.action = "checkInsertGuest";
    try {
      const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.guest) {
        guestStore.setGuest(resp.data.guest);
        console.log("last guest");
        getLastBerechnung(resp.data.guest.guest_id);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  function makeSmall() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > 100) {
      styleSwitch.value = "margin-top:0px;";
      scrollVisible.value = false;
    } else {
      if (sharedDataStore.isDesktop()) {
        styleSwitch.value = "margin-top:40px;";
        scrollVisible.value = true;
      }
    }
  }

  async function checkLogin() {
    let select = {};
    select.action = "checkLogin";
    try {
      const resp = await BaseCalls.postUser(select);
      if (resp.data && resp.data.user) {
        console.log("userdate app");
        userStore.setUser(resp.data.user);
      }
    } catch (err) {
      console.log("error login");
    }
  }
  async function startLogout() {
    let select = {};
    select.action = "startLogout";
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data) {
        userStore.setUser(false);
        setLoading(false);
        router.push("/logout");
      }
    } catch (err) {
      setLoading(false);
    }
  }
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  function checkMenue() {
    const pattern =
      /^\/(angebot(\/(?!(id\/?\d*))[^\s]*)?|vergleich\/starten(\/.*)?)$/;
    if (pattern.test(route.path)) {
      console.log("closeMenu");
      stopMenue.value = true;
    } else {
      stopMenue.value = false;
    }
  }

  watch(
    () => [route.path, route.params, route.query],
    () => {
      // Die checkChat Funktion wird aufgerufen, sobald sich der Pfad ändert
      //checkChat();
      checkMenue();
      window.scrollTo(0, 0);
    },
    {}
  );

  onMounted(() => {
    //setLoading(true);
    if (sharedDataStore.isDesktop()) {
      styleSwitch.value = "margin-top:40px;";
      containerHeight.value = 40;
    } else {
      styleSwitch.value = "margin-top:0px;";
      containerHeight.value = 0;
    }
    // checkChat();
    checkLogin();
    checkInsertGuest();
    checkLinkReferrer();
    nextTick(() => {
      contentHeight.value = measureContentHeight(); // Erste Messung nach dem Mount

      watch(contentHeight, (newHeight, oldHeight) => {
        if (Math.abs(newHeight - oldHeight) > 50) {
          sendHeightToParent(newHeight);
        }
      });

      // Optional: Höhe regelmäßig überprüfen
      setInterval(() => {
        const newHeight = measureContentHeight();
        if (newHeight !== contentHeight.value) {
          contentHeight.value = newHeight;
        }
      }, 1000); // Überprüfen jede Sekunde
    });
  });

  window.addEventListener("scroll", makeSmall); // Fügen Sie den Event Listener hinzu, um `makeSmall` bei Scroll-Events auszuführen
</script>

<style>
  .fixedFooter {
    position: fixed; /* Fixiert den Div innerhalb des Ansichtsfensters */
    bottom: 0; /* Positioniert den Div am unteren Rand des Ansichtsfensters */
    left: 0; /* Stellt sicher, dass der Div sich über die gesamte Breite erstreckt */
    width: 100%; /* Stellt die Breite auf 100% des Ansichtsfensters ein */
    background-color: #f2f2f2; /* Dunkler Hintergrund für bessere Sichtbarkeit */
    color: grey; /* Weiße Textfarbe */
    text-align: left; /* Zentriert den Text innerhalb des Divs */
    padding: 8px 8px 8px 8px; /* Fügt oben und unten etwas Polsterung hinzu */
    z-index: 1000; /* Stellt sicher, dass der Footer über anderen Elementen schwebt */
  }
  ul {
    margin-left: 26px;
  }
  ol {
    margin-left: 26px;
  }
  .button_caps {
    text-transform: none !important;
  }
  .nocaps {
    text-transform: none !important;
  }
  .smallcaps {
    text-transform: none !important;
    letter-spacing: normal !important;
    font-size: 20px;
  }
  .smalltext {
    font-size: 12px;
  }
  .ggfont {
    font-family: "PT Serif", serif;
  }
  .ggfontbold {
    font-family: "PT Serif", serif;
    font-weight: bold;
  }
  .ggfontitalic {
    font-family: "PT Serif:", serif;
    font-style: italic;
  }
  .textnormal {
    font-size: 16px;
  }
  .title_strong {
    font-size: 20px;
    color: #555555 !important;
    font-family: "PT Serif:", serif;
    font-style: italic;
  }
  .redtext {
    color: #088a85 !important;
  }
  .underline_teaser {
    display: inline-block;
    color: #555555;
    border-bottom: 1px solid #088a85;
    padding-bottom: 2px;
  }
  .greytext {
    color: #555555;
  }
  .divider {
    border-bottom: 1px solid #088a85 !important;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .headerlink {
    font-family: "PT Serif", serif;
    text-decoration: none;
    cursor: pointer;
    color: #555555 !important;
    margin-left: 10px;
    margin-right: 10px;
  }
  .redground {
    background-color: #088a85 !important;
  }
  .greyground {
    background-color: #f2f2f2 !important;
  }
  .turkground {
    background-color: #088a85;
  }
  .blackground {
    background-color: #555555 !important;
  }
  a {
    color: #1976d2;
    text-decoration: none;
    cursor: pointer;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
</style>
