import { defineStore } from "pinia";
import { ref, onScopeDispose } from "vue"; // Import von ref aus Vue

export const useSharedDataStore = defineStore("sharedData", () => {
  // Initialisiere windowWidth mit einem Standardwert, falls window nicht verfügbar ist
  const windowWidth = ref(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Methode, um windowWidth basierend auf der aktuellen Fenstergröße zu aktualisieren
  const updateWindowWidth = () => {
    if (typeof window !== "undefined") {
      windowWidth.value = window.innerWidth;
    }
  };

  function getAktuellesJahr() {
    const now = new Date();
    let year = now.getFullYear();
    const month = now.getMonth(); // Die Monate sind 0-basiert (0 = Januar, 11 = Dezember)
    if (month >= 10) { // Ab November (Monat 10)
      year += 1;
    }
    return year;
  }

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(`Element mit der ID ${elementId} wurde nicht gefunden.`);
    }
  };

  function getSignType(element, kunde = {}, returnType = "full") {
    let signData;
    let title = "";
    let desc = "";
    let isVP = false;

    switch (element.del_sign_type) {
      case "standardSign":
        title = "Versicherung beantragen";
        if (element.del_sign_person != "versicherungsnehmer") {
          desc =
            "Ja, ich ( " +
            kunde.antNamePlusVp +
            ") möchte die Versicherung beantragen und bestätige die Richtigkeit meiner Angaben";
        }
        if (element.del_sign_person == "versicherungsnehmer") {
          desc =
            "Ja, ich ( " +
            kunde.antNameVp +
            ") möchte d!ie Versicherung beantragen und bestätige die Richtigkeit meiner Angaben";
        }
        break;
      case "kontoSign":
        title = "Lastschriftmandat";
        desc = "Ja, ich stimme dem Einzug der Versicherungsbeiträgen zu";
        break;
      case "produktSign":
        title = "Produktinformation erhalten";
        desc =
          "Ja,  ( " +
          kunde.antName +
          ") ich habe die Produktinformationen erhalten";
        break;
      default:
        title = "Kundenunterschrift";
        desc =
          "Ja, ich möchte die Versicherung beantragen und bestätige die Richtigkeit meiner Angaben";
        break;
    }
    if (element.del_sign_person == "versicherungsnehmer") {
      isVP = true;
      title = title + " -  Unterschrift des  Versicherungsnehmers";
    }
    if (element.del_sign_person == "kontoinhaber") {
      isVP = true;
      title = title + "  - Unterschrift des Kontoinhabers";
    }
    if (element.del_sign_person == "erziehung") {
      isVP = true;
      title = title + " - Unterschrift  des Erzieungsberechtigten";
    }
    if (kunde.versicheurngsnehmer != "" && isVP == false) {
      title = title + " - Unterschrift des Antragstellers";
    }

    signData = {
      title: title,
      desc: desc,
    };

    // Entscheidet, ob das vollständige Objekt oder nur der Titel zurückgegeben wird
    if (returnType === "title") {
      return signData.title;
    } else {
      return signData;
    }
  }

  function getStartLink(tarifType) {
    switch (tarifType) {
      case 0:
        return "/vergleich/starten";
      case 1:
        return "/vergleich/starten/brille";
      case 2:
        return "/vergleich/starten/heilpraktiker";
      case 3:
        return "/vergleich/starten/krankenhaus";
      default:
        return "/vergleich/starten";
    }
  }
  function getBeschreibungLink(tarifType, id) {
    switch (tarifType) {
      case 0:
        return "/zahnzusatzversicherung/id/" + id;
      case 1:
        return "/brillenversicherung/id/" + id;
      case 2:
        return "/heilpraktikerversicherung/id/" + id;
      case 3:
        return "/krankenhauszusatzversicherung/id/" + id;
      default:
        return "/zahnzusatzversicherung/id/" + id;
    }
  }
  function showFontColor(berechnungSegment = 0) {
    switch (berechnungSegment) {
      case 1:
        return "text-blue";
      case 2:
        return "text-orange";
      case 3:
        return "text-turk";
      case 4:
        return "text-cyan";
      default:
        return "text-blue";
    }
  }

  function showFontColorTarif(tarifType = 0) {
    switch (tarifType) {
      case 0:
        return "text-blue";
      case 1:
        return "text-orange";
      case 2:
        return "text-turk";
      case 3:
        return "text-cyan";
      default:
        return "text-blue";
    }
  }

  function showBtnColoBer(berechnungSegment = 0) {
    switch (berechnungSegment) {
      case 0:
        return "blue";
      case 1:
        return "blue";
      case 2:
        return "orange";
      case 3:
        return "turk";
      case 4:
        return "cyan";
      default:
        return "blue";
    }
  }

  function showSup(lastBerechnung) {
    if (lastBerechnung.ber_algo_view == 2) {
      return "Kind";
    } else if (
      lastBerechnung.ber_alter >= 60 &&
      lastBerechnung.ber_segment <= 1
    ) {
      return "60Plus";
    } else if (
      lastBerechnung.ber_algo_view != 2 &&
      lastBerechnung.ber_segment <= 1
    ) {
      return "Zahn";
    } else if (lastBerechnung.ber_segment == 2) {
      return "Brille";
    } else if (lastBerechnung.ber_segment == 3) {
      return "HeilPr.";
    } else if (lastBerechnung.ber_segment == 4) {
      return "KH";
    } else {
      return new Date().getFullYear().toString();
    }
  }

  function showBtnColor(tarif_type) {
    switch (tarif_type) {
      case 0:
        return "blue";
      case 1:
        return "orange";
      case 2:
        return "turk";
      case 3:
        return "cyan";
      default:
        return "black";
    }
  }

  function getSegmentName(tarif_type) {
    switch (tarif_type) {
      case 0:
        return "Zahnzusatzversicherung";
      case 1:
        return "Brillenversicherung";
      case 2:
        return "Heilpraktikerversicherung";
      case 3:
        return "Krankenhauszusatzversicherung";
      default:
        return "Zahnzusatzversicherung";
    }
  }

  // Füge Event-Listener hinzu, wenn der Store initialisiert wird und window verfügbar ist
  if (typeof window !== "undefined") {
    window.addEventListener("resize", updateWindowWidth);

    // Stelle sicher, dass der Event-Listener entfernt wird, wenn der Store nicht mehr benötigt wird
    onScopeDispose(() => {
      window.removeEventListener("resize", updateWindowWidth);
    });
  }



  // Die Funktionen, die du verwenden möchtest
  const isMobile = () => windowWidth.value < 780;
  const isIpadAndBelow = () => windowWidth.value < 1450;
  const showMenueRechts = () => windowWidth.value >= 1450;
  const isDesktop = () => windowWidth.value >= 770;

  // Gib die reaktiven Daten und Methoden zurück
  return {
    windowWidth,
    getAktuellesJahr,
    isMobile,
    isIpadAndBelow,
    isDesktop,
    getStartLink,
    showBtnColor,
    showFontColor,
    showSup,
    getBeschreibungLink,
    showFontColorTarif,
    showBtnColoBer,
    scrollToElement,
    getSegmentName,
    showMenueRechts,
    getSignType,
  };
});
