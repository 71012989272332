import { createRouter, createWebHistory } from "vue-router";
// import { isEqual } from "lodash";
import RechnerView from "../views/rechner/RechnerView.vue";
import AllerechnerView from "../views/rechner/AllerechnerView.vue";
import ErgebnisView from "../views/rechner/ErgebnisView.vue";
import AntragfragenView from "../views/rechner/AntragfragenView.vue";
import PersonalfragenView from "../views/rechner/PersonalfragenView.vue";
import AntragOkView from "../views/rechner/AntragOkView.vue";
//seo
import HomeView from "../views/site/HomeView.vue";
import IndexBestellung from "../views/user/IndexBestellung.vue";
import RegisterUser from "../views/user/RegisterUser.vue";
import TarifBeschreibung from "../views/site/TarifBeschreibung.vue";
import IndexBegrenzung from "../views/site/content/IndexBegrenzung.vue";
import IndexWartezeit from "../views/site/content/IndexWartezeit.vue";
import IndexTest from "../views/site/content/IndexTest.vue";
import IndexUeber from "../views/site/content/IndexUeber.vue";
import IndexKind from "../views/site/content/IndexKind.vue";
import IndexPsycho from "../views/site/content/IndexPsycho.vue";
import IndexVorerkrankung from "../views/site/content/IndexVorerkrankung.vue";
import IndexSinnvoll from "../views/site/content/IndexSinnvoll.vue";
import IndexGesundheitsfragen from "../views/site/content/IndexGesundheitsfragen.vue";
import DebatteGkv from "../views/site/DebatteGkv.vue";
import IndexRealwert from "../views/site/content/IndexRealwert.vue";
import IndexImpressum from "../views/site/content/IndexImpressum.vue";
import IndexLogout from "../views/site/content/IndexLogout.vue";
import IndexAccount from "../views/site/content/IndexAccount.vue";
import SofortBestellung from "../views/site/content/SofortBestellung.vue";
import DirectLogin from "../views/site/content/DirectLogin.vue";
import TarifKuendigen from "../views/site/content/TarifKuendigen.vue";
import IndexBrille from "../views/site/content/IndexBrille.vue";
import IndexFamilie from "../views/site/content/IndexFamilie.vue";
import IndexForumaktion from "../views/site/content/IndexForumaktion.vue";
const routes = [
  {
    path: "/",

    name: "home",
    component: HomeView,
  },
  {
    path: "/vergleich/ergebnis/:token",
    name: "ergebnis",
    component: ErgebnisView,
  },
  {
    path: "/fuer_heilpraktiker_kostenlos",
    name: "kostenlos_anfordern",
    component: IndexForumaktion,
  },
  {
    path: "/angebot/kostenlos/:tarif_id/:token",
    name: "antragfragen",
    component: AntragfragenView,
  },
  {
    path: "/angebot/es_hat_geklappt/:tarif_id/:token",
    name: "antragfragenok",
    component: AntragOkView,
  },
  {
    path: "/angebot/kostenlos_fertig/:tarif_id/:token",
    name: "personalfragen",
    component: PersonalfragenView,
  },
  {
    path: "/vergleich/starten/:bertype?",
    name: "rechner",
    component: RechnerView,
  },
  {
    path: "/zusatzversicherung",
    name: "allerechner",
    component: AllerechnerView,
  },
  {
    path: "/kostenlos/anfordern",
    name: "bestellungen",
    component: IndexBestellung,
  },
  {
    path: "/nachbestellung/:token",
    name: "SofortBestellung",
    component: SofortBestellung,
  },
  {
    path: "/directlogin/:token",
    name: "DirectLogin",
    component: DirectLogin,
  },
  {
    path: "/heilpraktiker",
    name: "register",
    component: RegisterUser,
  },

  {
    path: "/heilpraktiker_zusatzversicherung/:key",
    name: "tarif",
    component: TarifBeschreibung,
  },

  {
    path: "/heilpraktiker_zusatzversicherung_ohne_begrenzungen",
    name: "ohnebegrenzung",
    component: IndexBegrenzung,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_ohne_wartezeit",
    name: "ohnewartezeit",
    component: IndexWartezeit,
  },
  {
    path: "/heilpraktiker_zusatzversicherungen_im_test",
    name: "indextest",
    component: IndexTest,
  },
  {
    path: "/ueber_mich",
    name: "ueber",
    component: IndexUeber,
  },
  {
    path: "/heilpraktikerversicherung_kind_vergleich",
    name: "kindvergleich",
    component: IndexKind,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_fuer_psychotherapie",
    name: "psycho",
    component: IndexPsycho,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_bei_vorerkrankung",
    name: "vorerkrankung",
    component: IndexVorerkrankung,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_sinnvoll",
    name: "sinnvooll",
    component: IndexSinnvoll,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_ohne_gesundheitsfragen",
    name: "gfragen",
    component: IndexGesundheitsfragen,
  },
  {
    path: "/realwert_heilpraktikerversicherung",
    name: "realwert",
    component: IndexRealwert,
  },
  {
    path: "/debatte_um_homoeopathie_leistung_der_gkv",
    name: "debattegkv",
    component: DebatteGkv,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: IndexImpressum,
  },
  {
    path: "/logout",
    name: "logout",
    component: IndexLogout,
  },
  {
    path: "/account",
    name: "stammdaten",
    component: IndexAccount,
  },

  {
    path: "/heilpraktiker_zusatzversicherung_kuendigen",
    name: "kuendigen",
    component: TarifKuendigen,
  },
  {
    path: "/heilpraktiker_und_brillenzusatzversicherungen",
    name: "brille",
    component: IndexBrille,
  },
  {
    path: "/heilpraktiker_zusatzversicherung_familie_partner",
    name: "familie",
    component: IndexFamilie,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
