<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-0" style="max-width: 1000px">
      <v-row class="mb-8">
        <v-col cols="12" md="8">
          <h2 v-if="showError" class="text-red">
            Fehler - leider gab es bei der Übertragung einen Fehler. Am besten
            schicken Sie uns eine kurze E-Mail: kl@dornsteintabelle.de
          </h2>
          <h2 v-if="!showSuccess && !showError">
            DornsteinTabelle kostenlos nachbestellen
          </h2>
          <h2 class="text-green" v-if="showSuccess && !showError">
            Ihre kostenlose Nachbestellung der DornsteinTabellen wird so schnell
            wie möglich versendet
          </h2>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";

  import { useRoute ,useRouter} from "vue-router";

  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import { useUserStore } from "@/stores/user";

  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();
  const showSuccess = ref(false);
  const showError = ref(false);
  const { proxy } = getCurrentInstance();
  const route = useRoute();
  const router = useRouter();
  async function checkLoginToken() {
    if (!route.params.token) {
      return;
    }
    let select = {};
    select.token = route.params.token;
    select.action = "startlogintoken";

    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.user) {
        userStore.setUser(resp.data.user);
        showError.value = false;
        showSuccess.value = true;
        setLoading(false);
        router.push("/kostenlos/anfordern");
      } else {
        showError.value = true;
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim einloggen.");
    }
  }
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  onMounted(() => {
    checkLoginToken();
    proxy.$updateDocumentHead("Jetzt Dornsteintabelle  nachbestellen", "");
  });
</script>
