<template>
    <div style="min-height: 1000px;">
      <v-row>
        <v-col cols="9">
            <h2 class="mt-8">Der kostenlose Dornstein-Service für Heilpraktiker</h2><br>
<em>
Liebe Heilpraktikerinnen,
liebe Heilpraktiker,
<br><br>
schreiben Sie mir einfach eine kurze Nachricht mit Ihrem Namen und Ihrer Praxisadresse, um Ihre DornsteinTabellen kostenfrei zu erhalten.
<br><br>
<b>Senden Sie mir einfach eine Nachricht an:</b><br>
Email an: <span class="text-blue">kilian.link@dornsteintabelle.de</span> oder <br>eine <b>WhatsApp</b> an:  01575 28 44 887<br>
Einfach Ihren Namen und Ihre Praxisadresse angeben.
<br><br>
Sie erhalten Ihre DornsteinTabelle ToGo im praktischen Postkartenformat (100 Stück) inkl. einem hochwertigen Acryl-Aufsteller für Ihre Praxisräume kostenlos per Post / DHL zugestellt.

<!-- Kilian Link -->
</em>


<!-- Fügen Sie hier das Bild ein -->

<br><br>
<v-divider></v-divider>
<br><br>
<h3>3 schnelle Fakten über die DornsteinTabelle</h3> 
<ul>
<li>Die DornsteinTabelle hat sich mittlerweile zur führenden Vergleichstabelle bei Heilpraktikern entwickelt.</li>
<li>Die DornsteinTabelle steht jetzt seit 2 Jahren allen Heilpraktikern kostenlos zur Verfügung. </li>
<li>Mittlerweile stellen bereits über 2.000 Heilpraktiker die DornsteinTabelle Ihren Patienten zur Verfügung.</li>
</ul>

        </v-col>
        <v-col cols="3">
            <h2 class="mt-8 text-green">Jetzt per WhatsApp bestellen</h2>
            <img style="width: 260px;" :src="require('@/assets/site/whatsapp.png')" alt="WhatsApp Kontakt" />

            
            <v-card class="d-flex flex-column">
                      <v-img
                        :src="getImagePath('Praxis Claudia Meixner.png')"
                        class="text-white align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="280"
                      >
                        <!-- Verwende einen Container, um die Größe und den Überlauf des Titels zu steuern -->
                        <div
                          class="title-container d-flex align-end flex-grow-1"
                        >
                          <h3 class="text-white">Heilquartier Claudia Meixner in Gelsenkirchen</h3>
                        </div>
                      </v-img>
                    </v-card>
        
        </v-col>
      </v-row>
    </div>
  </template>

  <script setup>
    import { getCurrentInstance, onMounted } from "vue";
    const { proxy } = getCurrentInstance();
    function getImagePath(imageName) {
    return require(`@/assets/user/${imageName}`);
  }
    onMounted(() => {
      proxy.$updateDocumentHead("Kostenlos die DornsteinTabelle bestellen", "Jetzt innerhalb von nur 2 Minuten die DornsteinTabelle für Ihre Heilpraktiker-Praxis kostenlos bestellen.");
    });
  </script>
