<template>
  <div style="overflow-wrap: break-word;" class="pr-1">
    <v-row>
      <v-col cols="12">
        <div v-if="user.user_id > 0">
          <h3>Ihr Account</h3>
          <ul>
            <li>{{ user.user_name }}</li>
            <li>
              <router-link to="/kostenlos/anfordern">DornsteinTabellen anfordern</router-link>
            </li>
            <li>
              <router-link to="/account">Account bearbeiten</router-link>
            </li>
          </ul>
          <v-divider class="mt-2 my-4"></v-divider>
        </div>
        <h3>Rechner starten</h3>
        Vergleichen Sie die leistungsstärksten Heilpraktiker-Zusatzversicherungen.
        <router-link to="/vergleich/starten/heilpraktiker">
          <v-btn color="turk" rounded="0" class="mt-2 button_caps">Vergleich starten</v-btn>
        </router-link>

        <!-- Liste mit nummerierten Icons -->
    <div  v-for="(event) in timelineEvents" :key="event.date" class="mt-4 pa-2" style="background-color: #f2f2f2;">
      <h4>{{ event.date }}</h4>
      <p style="font-size: 13px;">{{ event.description }}</p>
    </div>

        <!-- Optionaler zusätzlicher Inhalt -->
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
import { useSharedDataStore } from "@/stores/sharedData";
const userStore = useUserStore();
const user = computed(() => userStore.userData);
const common = useSharedDataStore();


const timelineEvents = [
  {
    date: "Mittlerweile",
    description:
      "Die DornsteinTabelle hat sich zur führenden Vergleichstabelle bei Heilpraktikern entwickelt.",
  },
  {
    date: "Seit über 2 Jahren",
    description:
      "Die DornsteinTabelle steht allen Heilpraktikern kostenlos zur Verfügung.",
  },
  {
    date: "Über 2.500 Heilpraktiker",
    description:
      "empfehlen die DornsteinTabelle im Patientengespräch.",
  },
  {
    date: "Stand Oktober " + common.getAktuellesJahr(),
    description:
      "Über 2.000 Heilpraktiker stellen die DornsteinTabelle ToGo ihren Patienten in der Praxis zur Verfügung.",
  },
  {
    date: "Seit September 2022",
    description:
      "Über 300 Heilpraktiker haben die DornsteinTabelle auf ihrer Praxis-Homepage verlinkt.",
  },
];
</script>

<style scoped>
.timeline-title,
.timeline-subtitle {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

.v-list-item-subtitle {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

.v-list-item-content {
  max-width: none;
}
</style>
